import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-navbar-title-list-map',
  templateUrl: './navbar-title-list-map.component.html',
  styleUrls: ['./navbar-title-list-map.component.css']
})
export class NavbarTitleListMapComponent implements OnInit {

  @Input() title: string;
  @Input() listLink: string;
  @Input() mapLink: string;

  constructor() {
  }

  ngOnInit(): void {
  }

}
