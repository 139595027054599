import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {DataService} from '../shared/services/data.service';
import {City} from '../shared/models/city.model';
import {AuthService} from '../auth/auth.service';
import {SocialAuthService, SocialUser} from '@abacritt/angularx-social-login';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: [
    '../shared/styles/forms-style.css',
    './register.component.css'
  ]
})
export class RegisterComponent implements OnInit, OnDestroy {
  registerForm: UntypedFormGroup;
  requestInProgress: boolean;
  isLoading: boolean;
  formSubmitted: boolean;
  error: boolean;
  serverMessage: string;
  cities: City[];

  authServiceUserSubscription: Subscription | undefined;
  /*socialAuthServiceLoginSubscription: Subscription | undefined;
  user: SocialUser | undefined;
  loggedIn = false;*/

  constructor(
    public router: Router,
    private authService: AuthService,
    private dataService: DataService,
    // private socialAuthService: SocialAuthService
  ) {
  }

  ngOnInit(): void {
    this.authServiceUserSubscription = this.authService.user.subscribe(user => {
      if (user) {
        this.authService.onLoginSuccess();
      }
    });

    this.dataService.getCities().subscribe(cities => {
      this.cities = cities;
      this.registerForm.controls.city.setValue('878');
    });

    this.registerForm = new UntypedFormGroup({
      firstName: new UntypedFormControl('', [Validators.required]),
      lastName: new UntypedFormControl('', [Validators.required]),
      email: new UntypedFormControl('', [Validators.required, Validators.email]),
      password: new UntypedFormControl('', [Validators.required]),
      phone: new UntypedFormControl('', [Validators.required]),
      city: new UntypedFormControl(this.cities, [Validators.required]),
      street: new UntypedFormControl(''),
      houseNumber: new UntypedFormControl('')
    });

    /*this.socialAuthServiceLoginSubscription = this.socialAuthService.authState.subscribe((user) => {
      this.loggedIn = (user != null);
      if (user) {
        this.user = user;
        this.authService.onLoginWithSocialProfile(user).subscribe(res => {
          this.isLoading = false;
          this.authService.onLoginSuccess();
        }, error => {
          this.isLoading = false;
        });
      }
    });*/
  }

  submitRegister(): void {
    this.formSubmitted = true;
    this.error = false;
    if (this.registerForm.valid && parseInt(this.registerForm.controls.city.value, 10) > 0) {
      this.isLoading = true;
      this.requestInProgress = true;
      this.authService.onRegister(this.registerForm.value).subscribe(res => {
        this.isLoading = false;
        this.requestInProgress = false;
        this.serverMessage = res.message;
        if (parseInt(res.status, 10) === 1) {
          setTimeout(() => {
            this.authService.onLoginSuccess();
          }, 2000);
        } else {
          this.error = true;
        }
      }, err => {
        this.error = true;
        this.serverMessage = err.message;
      });
    }
  }

  ngOnDestroy(): void {
    if (this.authServiceUserSubscription) {
      this.authServiceUserSubscription.unsubscribe();
    }
    /*if (this.socialAuthServiceLoginSubscription) {
      this.socialAuthServiceLoginSubscription.unsubscribe();
    }*/
  }

}
