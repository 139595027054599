import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Festival} from '../shared/models/festival.model';
import {Subscription} from 'rxjs';
import {FestivalService} from '../shared/services/festival.service';
import {Event} from '../shared/models/event.model';
import {User} from '../shared/models/user.model';
import {AuthService} from '../auth/auth.service';

@Component({
  selector: 'app-navbar-edit-event',
  templateUrl: './navbar-edit-event.component.html',
  styleUrls: ['./navbar-edit-event.component.css']
})
export class NavbarEditEventComponent implements OnInit, OnDestroy {

  @Input() event: Event;
  user: User;
  userSubscription: Subscription;
  festival: Festival;
  festivalSubscription: Subscription;

  constructor(
    private festivalService: FestivalService,
    private authService: AuthService,
  ) {
  }

  ngOnInit(): void {
    this.userSubscription = this.authService.user.subscribe(user => {
      this.user = user;
    });
    this.festivalSubscription = this.festivalService.currentFestivalSubject.subscribe(festival => {
      this.festival = festival;
    });
  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
    this.festivalSubscription.unsubscribe();
  }

}
