<div class="container-fluid mb-10 p-0">
  <div class="d-flex page-navbar">
    <span>איפוס סיסמא</span>
  </div>
  <div class="text-center" *ngIf="isLoading">
    <app-loading-spinner></app-loading-spinner>
  </div>
  <form class="pt-5" [formGroup]="restorePasswordForm" (ngSubmit)="onSubmit()" *ngIf="!isLoading">
    <div>
      <label class="form-label mb-4">
        <input type="email" formControlName="email" class="form-control" placeholder="אימייל" autocomplete="off">
      </label>
      <label class="form-label">
        <input type="password" class="form-control" formControlName="password" placeholder="סיסמא">
      </label>
    </div>
    <div class="text-center">
      <button class="btn btn-submit w-100 font-size-22 font-weight-500" type="submit" [disabled]="restorePasswordForm.invalid">שמירת סיסמא חדשה</button>
    </div>
    <div class="mt-3 alert alert-danger" *ngIf="errorMessage">{{errorMessage}}</div>
    <div class="mt-3 alert alert-success" *ngIf="message">{{message}}</div>
  </form>
</div>
