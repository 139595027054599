<div class="container-fluid vh-100 ps-0 pe-0">
  <app-navbar-title-list-map title="בחרו את הפסטיבל אליו אתם רוצים להעלות יוזמות או מקומות"></app-navbar-title-list-map>
  <div class="h-100 festivals-box p-4">
    <div class="col-sm-12 col-md-2 festival-box h-25 mb-4" *ngFor="let festival of festivals">
      <a routerLink="/festival/{{festival.festivalEnglishName}}">
        <img src="https://api.hanadiv.org/images/{{festival.logo}}" alt="">
      </a>
    </div>
  </div>
</div>
