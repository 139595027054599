import {Component, Input, OnInit} from '@angular/core';
import {Festival} from '../shared/models/festival.model';
import {Subscription} from 'rxjs';
import {FestivalService} from '../shared/services/festival.service';

@Component({
  selector: 'app-navbar-title-back',
  templateUrl: './navbar-title-back.component.html',
  styleUrls: ['./navbar-title-back.component.css']
})
export class NavbarTitleBackComponent implements OnInit {

  festival: Festival;
  isCollapsed = true;
  festivalSubscription: Subscription;
  @Input() title: string;

  constructor(private festivalService: FestivalService) {
  }

  ngOnInit(): void {
    this.festivalSubscription = this.festivalService.currentFestivalSubject.subscribe(festival => {
      this.festival = festival;
    });
  }
}
