import {Component, Input, OnInit} from '@angular/core';
import {Festival} from '../shared/models/festival.model';
import {faEnvelope} from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-navbar-title-my-messages',
  templateUrl: './navbar-title-my-messages.component.html',
  styleUrls: ['./navbar-title-my-messages.component.css']
})
export class NavbarTitleMyMessagesComponent implements OnInit {


  @Input() festival: Festival;
  @Input() title: string;
  faEnvelope = faEnvelope;
  unreadMessages: boolean; // todo

  constructor() {
  }

  ngOnInit(): void {
  }

}
