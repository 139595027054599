import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {FestivalService} from '../shared/services/festival.service';
import {DataService} from '../shared/services/data.service';
import {Message} from '../shared/models/message.model';
import {Subscription} from 'rxjs';
import {Festival} from '../shared/models/festival.model';
import {MessageService} from '../shared/services/message.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {faTimesCircle} from '@fortawesome/free-solid-svg-icons';
import {FormatDataService} from '../shared/services/format-data.service';

@Component({
  selector: 'app-my-messages',
  templateUrl: './my-messages.component.html',
  styleUrls: ['./my-messages.component.css']
})
export class MyMessagesComponent implements OnInit, OnDestroy {

  festival: Festival;
  festivalSubscription: Subscription;
  messages: Message[];
  currentMessage: Message;
  messagesSubscription: Subscription;
  faTimesCircle = faTimesCircle;
  dataLoaded: boolean;

  constructor(
    private route: ActivatedRoute,
    private festivalService: FestivalService,
    private messageService: MessageService,
    private dataService: DataService,
    private formatDataService: FormatDataService,
    private modalService: NgbModal
  ) {
  }

  ngOnInit(): void {
    this.festivalSubscription = this.festivalService.festivalsSubject.subscribe(festivals => {
      if (festivals) {
        const festivalEnglishName = this.route.snapshot.paramMap.get('festivalEnglishName');
        this.festival = this.festivalService.onGetCurrentFestival(festivalEnglishName);
        this.getMessages();
      }
    });
  }

  getMessages(): void {
    this.messageService.getMyMessages(this.festival.festivalEnglishName);
    this.messagesSubscription = this.messageService.messagesSubject.subscribe(messages => {
      if (messages) {
        this.messages = messages;
        setTimeout(() => {
          this.dataLoaded = true;
        }, 600);
      }
    });
  }

  showMessage(message, messageModalContent): void {
    this.currentMessage = message;
    this.open(messageModalContent);
    this.update({messageID: message.messageID, messageRead: true});
  }

  open(content): void {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
    }, (reason) => {
    });
  }

  formatAsDay(date: string): string {
    return this.formatDataService.formatAsDay(date);
  }

  dismissOffer(): void {
    this.update({messageID: this.currentMessage.messageID, statusID: 2});
  }

  createEvent(eventCreatedModal): void {
    this.update({messageID: this.currentMessage.messageID, statusID: 3});
    this.open(eventCreatedModal);
  }

  update(data): void {
    this.messageService.update(this.festival.festivalEnglishName, data);
  }

  ngOnDestroy(): void {
    this.messagesSubscription.unsubscribe();
  }

}
