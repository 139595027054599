<div class="container-fluid vh-100 ps-0 pe-0" *ngIf="festival">
  <app-navbar-title-my-messages [festival]="festival" title="{{festival.name}}"></app-navbar-title-my-messages>
  <div class="d-block d-sm-none h-50 mb-5 justify-content-center options-box">
    <div class="col-sm-12 col-md-6 d-flex flex-wrap align-content-end h-55 mt-3 pb-4 option-box justify-content-center image-box offer-initiative-box">
      <button type="button" routerLink="/festival/{{festival.festivalEnglishName}}/add-initiative/1" class="btn btn-primary col-7 offer-initiative">יש לי יוזמה</button>
    </div>
    <div class="col-sm-12 col-md-6 d-flex flex-wrap align-content-end h-55 mt-3 pb-4 option-box justify-content-center image-box offer-place-box">
      <button type="button" routerLink="/festival/{{festival.festivalEnglishName}}/add-place/1" class="btn btn-primary col-7 offer-place">יש לי מקום לארח</button>
    </div>
  </div>
  <div class="d-none d-sm-block mt-5">
    <div class="d-flex justify-content-center font-size-31">מה תירצו לעשות?</div>
    <div class="d-flex justify-content-center">
      <div class="col-sm-5 col-md-3 d-flex flex-wrap align-content-end mt-3 pb-4 option-box justify-content-center image-box offer-initiative-box">
        <button type="button" routerLink="/festival/{{festival.festivalEnglishName}}/add-initiative/1" class="btn offer-initiative">יש לי יוזמה</button>
      </div>
      <div class="col-sm-5 col-md-3 d-flex flex-wrap align-content-end mt-3 pb-4 option-box justify-content-center image-box offer-place-box">
        <button type="button" routerLink="/festival/{{festival.festivalEnglishName}}/add-place/1" class="btn offer-place">יש לי מקום לארח</button>
      </div>
    </div>
  </div>

  <app-footer-navbar></app-footer-navbar>
</div>
