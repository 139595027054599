<div class="container-fluid p-0">
  <app-navbar-title-subtitle-step-back [festival]="festival" title="יצירת יוזמה" subTitle="הרכב/קבוצה" step="3" totalSteps="6"></app-navbar-title-subtitle-step-back>
  <div class="container-fluid desktop-content-box h-100 festivals-box">
    <div class="mt-4 mb-4 d-flex col-12 font-size-17">האם יש עוד משתתפים ביוזמה?</div>
    <div class="more-participants-box mb-4">
      <div class="d-flex" (click)="open(addParticipantModal)">
        <div class="col">משתתפים</div>
        <img id="plus-icon" src="assets/plus.svg" alt="הוספת משתתף או משתתפת">
      </div>
      <div *ngIf="!participantsList || participantsList.length===0" class="d-flex col font-size-12 mt-4">לא נוספו משתתפים</div>
      <div *ngFor="let item of participantsList; let i = index" class="mt-2 mb-2">
        <span class="font-size-17 font-weight-bold">{{item.firstName}} </span>
        <span class="font-size-17 font-weight-bold">{{item.lastName}} </span>
        <span>| {{item.role}}</span>
        <span class="float-left">
          <fa-icon [icon]="faTrash" (click)="removeUser(i)"></fa-icon>
        </span>
      </div>
    </div>
    <form [formGroup]="addInitiativeForm" (ngSubmit)="onSubmit()">
      <label class="form-label">
        <input type="text" class="form-control" placeholder="שם ההרכב/קבוצה" formControlName="groupTitle" (change)="resetAddGroupNameError(false)">
      </label>
      <div *ngIf="showAddGroupNameError" class="color-red">שכחתם לציין איך קוראים לכם?</div>

      <div class="mt-4 mb-4 d-flex col-12 font-size-17">קישור חיצוני 1</div>
      <label class="form-label">
        <input type="text" class="form-control" placeholder="כותרת לקישור" formControlName="externalLinkLabel1">
      </label>
      <label class="form-label">
        <input type="text" class="form-control" placeholder="קישור חיצוני" formControlName="externalLink1">
      </label>

      <div class="mt-4 mb-4 d-flex col-12 font-size-17">קישור חיצוני 2</div>
      <label class="form-label">
        <input type="text" class="form-control" placeholder="כותרת לקישור" formControlName="externalLinkLabel2">
      </label>
      <label class="form-label">
        <input type="text" class="form-control" placeholder="קישור חיצוני" formControlName="externalLink2">
      </label>
      <!--<div class="color-red mb-1" *ngIf="formSubmitted && !addInitiativeForm.controls.externalLink2.value && isVirtual">בחרתם לפרסם אירוע וירטואלי, נא להזין את כתובת המפגש הוירטואלי</div>-->
      <div class="text-center mt-3">
        <button class="btn btn-submit mb-5 w-100 font-size-22" type="submit">להוספת תמונות</button>
      </div>
    </form>
  </div>
</div>

<ng-template #addParticipantModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">הוספת משתתף</h4>
  </div>
  <div class="modal-body">
    <form [formGroup]="addInitiativeMoreParticipantsForm" (ngSubmit)="onSubmitParticipant(modal);">
      <label class="form-label">
        <input type="text" class="form-control" placeholder="שם פרטי" formControlName="firstName">
      </label>
      <div class="color-red" *ngIf="addInitiativeMoreParticipantsFormSubmitted && !addInitiativeMoreParticipantsForm.controls.firstName.valid">נא להזין שם פרטי</div>
      <label class="form-label">
        <input type="text" class="form-control" placeholder="שם משפחה" formControlName="lastName">
      </label>
      <div class="color-red" *ngIf="addInitiativeMoreParticipantsFormSubmitted && !addInitiativeMoreParticipantsForm.controls.lastName.valid">נא להזין שם משפחה</div>
      <label class="form-label">
        <input type="text" class="form-control" placeholder="תפקיד" formControlName="role">
      </label>
      <div class="color-red" *ngIf="addInitiativeMoreParticipantsFormSubmitted && !addInitiativeMoreParticipantsForm.controls.role.valid">נא להזין תפקיד</div>
      <div class="modal-footer">
        <button class="col btn btn-dismiss font-size-18 pe-3 ps-3" type="button" (click)="modal.close()">ביטול</button>
        <button class="col btn btn-submit font-size-18 pe-3 ps-3" type="submit">הוספה</button>
      </div>
    </form>
  </div>
</ng-template>
