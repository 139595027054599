import {Component, OnDestroy, OnInit} from '@angular/core';
import {Festival} from '../shared/models/festival.model';
import {Initiative} from '../shared/models/initiative.model';
import {ActivatedRoute} from '@angular/router';
import {FestivalService} from '../shared/services/festival.service';
import {DataService} from '../shared/services/data.service';
import {Subscription} from 'rxjs';
import {AuthService} from '../auth/auth.service';
import {Place} from '../shared/models/place.model';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormatDataService} from '../shared/services/format-data.service';
import {User} from '../shared/models/user.model';
import {MessageService} from '../shared/services/message.service';
import {faTimesCircle} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-initiative',
  templateUrl: './initiative.component.html',
  styleUrls: ['./initiative.component.css']
})
export class InitiativeComponent implements OnInit, OnDestroy {
  user: User;
  festival: Festival;
  festivalsSubscription: Subscription;
  initiative: Initiative;
  userSubscription: Subscription;
  userPlacesSubscription: Subscription;
  initiativeSubscription: Subscription;
  userPlaces: Place[] = [];
  userAvailablePlaces: Place[] = [];
  eligible: boolean;
  submitted: boolean;
  faTimesCircle = faTimesCircle;
  selectedUserPlace: Place;
  errorMessage: string;
  userSelectedEventTime: { date: string, hour: number };

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private festivalService: FestivalService,
    private dataService: DataService,
    private formatDataService: FormatDataService,
    private messagesService: MessageService,
    private modalService: NgbModal
  ) {
  }

  ngOnInit(): void {
    this.userSubscription = this.authService.user.subscribe(user => {
      this.user = user;
    });

    this.festivalsSubscription = this.festivalService.festivalsSubject.subscribe(festivals => {
      if (festivals) {
        const festivalEnglishName = this.route.snapshot.paramMap.get('festivalEnglishName');
        this.festival = this.festivalService.onGetCurrentFestival(festivalEnglishName);
        this.getInitiative();
        this.dataService.getMyPlacesSubscription(this.festival.festivalEnglishName);
      }
    });

    this.subscribeToUserPlaces();
  }

  getInitiative(): void {
    const initiativeID = this.route.snapshot.paramMap.get('initiativeID');
    this.initiativeSubscription = this.dataService.getInitiative(this.festival.festivalEnglishName, initiativeID).subscribe(initiative => {
      if (initiative) {
        this.initiative = initiative[0];
      }
    });
  }

  subscribeToUserPlaces(): void {
    this.userPlacesSubscription = this.dataService.userPlacesSubject.subscribe(userPlaces => {
      this.userPlaces = userPlaces;
      this.userAvailablePlaces = this.userPlaces.filter(item => item.numberOfInitiativesWillingToHost - item.numberOfEventsAtPlace > 0);
    });
  }

  selectHour(date, hour): void {
    this.submitted = false;
    this.eligible = false;
    this.userSelectedEventTime = {date, hour};
  }

  isEligible(): boolean {
    return !!this.userSelectedEventTime;
  }

  isHourSelected(date, hour): boolean {
    if (this.userSelectedEventTime) {
      if (this.userSelectedEventTime.date === date && this.userSelectedEventTime.hour === hour) {
        return true;
      }
    }
    return false;
  }

  isHourUnavailable(date, hour): boolean {
    if (date && hour) {
      const notAvailableDate = this.selectedUserPlace.placeIsNotAvailableAtDateTime.find(item => item.date === date);
      return notAvailableDate?.fromHours.includes(hour) || notAvailableDate?.blockedHours.includes(hour) || false;
    }
  }

  onSubmit(selectPlacesModalContent, selectInvitationDateTime): void {
    this.submitted = true;
    this.eligible = true;
    if (this.userAvailablePlaces.length > 1) {
      this.open(selectPlacesModalContent);
    } else if (this.userAvailablePlaces.length === 1) {
      this.selectedUserPlace = this.userAvailablePlaces[0];
      this.proceedToChooseDateTime(selectInvitationDateTime);
    } else {
      this.errorMessage = 'יש להוסיף מקום אירוח טרם שליחת בקשה לארח יוזמה';
      return;
    }
  }

  onCreateEvent(eventCreatedModal): void {
    this.eligible = true;
    const data = {
      placeID: this.selectedUserPlace.placeID,
      initiativeID: this.initiative.initiativeID,
      date: this.userSelectedEventTime.date,
      hour: this.userSelectedEventTime.hour
    };

    this.dataService.createEvent(this.festival.festivalEnglishName, data).subscribe(res => {
      this.open(eventCreatedModal);
    });
  }

  open(content): void {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
    }, (reason) => {
    });
  }

  formatAsDay(date: string): string {
    return this.formatDataService.formatAsDay(date);
  }

  selectUserPlace(place): void {
    console.log(place);
    this.selectedUserPlace = place;
  }

  isUserPlaceSelected(place): boolean {
    if (!this.selectedUserPlace) {
      return false;
    }
    return this.selectedUserPlace.placeID === place.placeID;
  }

  proceedToChooseDateTime(selectInvitationDateTime): void {
    if (this.selectedUserPlace) {
      this.open(selectInvitationDateTime);
    }
  }

  proceedToSendMessage(selectInvitationDateTime): void {
    if (this.selectedUserPlace) {
      this.open(selectInvitationDateTime);
    }
  }

  sendMessage(messageSentModalContent): void {
    if (this.selectedUserPlace) {
      const req = {
        fromUserID: this.user.userID,
        toUserID: this.initiative.ownerID,
        regardingInitiativeID: this.initiative.initiativeID,
        regardingPlaceID: this.selectedUserPlace.placeID,
        requestedDate: this.userSelectedEventTime.date,
        requestedHour: this.userSelectedEventTime.hour,
      };
      this.messagesService.onSendMessage(this.festival.festivalEnglishName, req);
      this.open(messageSentModalContent);
    }
  }

  ngOnDestroy(): void {
    this.festivalsSubscription.unsubscribe();
    this.userSubscription.unsubscribe();
    this.userPlacesSubscription.unsubscribe();
    this.initiativeSubscription.unsubscribe();
  }
}
