import {Component, OnDestroy, OnInit} from '@angular/core';
import {Festival} from '../shared/models/festival.model';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FestivalService} from '../shared/services/festival.service';
import {DataService} from '../shared/services/data.service';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-add-place5',
  templateUrl: './add-place5.component.html',
  styleUrls: [
    '../shared/styles/add-initiative-shared.css',
    '../shared/styles/forms-style.css',
    './add-place5.component.css'
  ]
})
export class AddPlace5Component implements OnInit, OnDestroy {

  localStorageKey = 'addPlaceFormData';
  festival: Festival;
  festivalsSubscription: Subscription;
  agree: boolean;
  closeResult = '';
  showFooter: boolean;

  constructor(private modalService: NgbModal,
              private festivalService: FestivalService,
              private dataService: DataService,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.festivalsSubscription = this.festivalService.festivalsSubject.subscribe(festivals => {
      if (festivals) {
        const festivalEnglishName = this.route.snapshot.paramMap.get('festivalEnglishName');
        this.festival = this.festivalService.onGetCurrentFestival(festivalEnglishName);
      }
    });
  }

  onSubmit(placeCreatedModal): void {
    if (this.agree) {
      this.showFooter = true;
      this.dataService.save(this.localStorageKey, this.festival.festivalEnglishName);
      this.open(placeCreatedModal);
    }
  }

  open(content): void {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
    }, (reason) => {
    });
  }

  ngOnDestroy(): void {
    this.festivalsSubscription.unsubscribe();
  }

}
