<nav class="navbar fixed-bottom">
  <a class="col" routerLink="/festival/{{festivalEnglishName}}/my-area" #profileRouterLinkActive="routerLinkActive" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
    <img class="icon" [src]="profileRouterLinkActive.isActive?'assets/user-active.svg':'assets/user.svg'" alt="User icon">
    <span>איזור אישי</span>
  </a>
  <a class="col" routerLink="/festival/{{festivalEnglishName}}/initiatives-list" #initiativesRouterLinkActive="routerLinkActive" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
    <img class="icon" [src]="initiativesRouterLinkActive.isActive?'assets/initiatives-active.svg':'assets/initiatives.svg'" alt="Initiative icon">
    <span>יוזמות</span>
  </a>
  <a class="col" routerLink="/festival/{{festivalEnglishName}}/options" #addRouterLinkActive="routerLinkActive" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
    <img id="add-icon" class="icon" [src]="addRouterLinkActive.isActive?'assets/add-active.svg':'assets/add.svg'" alt="Add icon">
    <span [class.color-light-peach]="!addRouterLinkActive.isActive">הוספה</span>
  </a>
  <a class="col" routerLink="/festival/{{festivalEnglishName}}/places-list" #placesRouterLinkActive="routerLinkActive" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
    <img class="icon" [src]="placesRouterLinkActive.isActive?'assets/places-active.svg':'assets/places.svg'" alt="Place icon">
    <span>מקומות</span>
  </a>
  <a class="col" href="https://{{festivalEnglishName}}.hanadiv.org" target="_blank">
    <img class="icon" src="assets/events.svg" alt="קישור לאפליקציית התוכנייה">
    <span>אירועים</span>
  </a>
</nav>
