import {Component, OnDestroy, OnInit} from '@angular/core';
import {Festival} from '../shared/models/festival.model';
import {Subscription} from 'rxjs';
import {Event} from '../shared/models/event.model';
import {ActivatedRoute, Router} from '@angular/router';
import {FestivalService} from '../shared/services/festival.service';
import {DataService} from '../shared/services/data.service';
import {faTrash} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-edit-event-admins',
  templateUrl: './edit-event-admins.component.html',
  styleUrls: ['./edit-event-admins.component.css']
})
export class EditEventAdminsComponent implements OnInit, OnDestroy {
  festival: Festival;
  festivalSubscription: Subscription;
  eventSubscription: Subscription;
  deleteSubscription: Subscription;
  event: Event;
  faTrash = faTrash;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private festivalService: FestivalService,
    private dataService: DataService
  ) {
  }

  ngOnInit(): void {
    this.festivalSubscription = this.festivalService.festivalsSubject.subscribe(festivals => {
      if (festivals) {
        const festivalEnglishName = this.route.snapshot.paramMap.get('festivalEnglishName');
        this.festival = this.festivalService.onGetCurrentFestival(festivalEnglishName);
        const eventID = this.route.snapshot.paramMap.get('eventID');
        this.eventSubscription = this.dataService.getEvent(festivalEnglishName, eventID).subscribe((events: Event[]) => {
          this.event = events[0];
        });
      }
    });
  }

  ngOnDestroy(): void {
    if (this.festivalSubscription) {
      this.festivalSubscription.unsubscribe();
    }
    if (this.eventSubscription) {
      this.eventSubscription.unsubscribe();
    }
    if (this.deleteSubscription) {
      this.deleteSubscription.unsubscribe();
    }
  }

}
