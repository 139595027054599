<div class="container-fluid mb-10 p-0">
  <div class="d-flex page-navbar">
    <div class="col-2">
      <app-previous-page-icon></app-previous-page-icon>
    </div>
    <div class="col-8">
      <div class="col-12 text-center mt-1 font-size-24">סנן מקומות</div>
    </div>
    <app-navbar-menu></app-navbar-menu>
  </div>

  <div class="desktop-content-box">
    <form [formGroup]="filterPlacesForm" (ngSubmit)="onSubmit()">
      <div class="col-12 mt-3">
        <div class="font-size-17">לכמה אנשים הפעילות מיועדת?</div>
        <div class="text-center">
          <div class="number-of-participants">{{numberOfParticipants}}</div>
          <mv-slider [(value)]="numberOfParticipants" [min]="minimumParticipants" [max]="maximumParticipants"></mv-slider>
        </div>
      </div>
      <div class="mt-4" *ngIf="dynamicFormData">
        <label class="w-100">
          <select class="form-control select-genre" formControlName="neighborhood">
            <option value="">שכונה</option>
            <option *ngFor="let neighborhood of dynamicFormData.neighborhoods" [ngValue]="neighborhood.neighborhoodID">
              {{ neighborhood.title }}
            </option>
          </select>
        </label>
      </div>
      <div class="mt-4" *ngIf="dynamicFormData">
        <div class="group-title font-size-17 pb-2 m-3">לאן היוזמה מתאימה?</div>
        <label *ngFor="let item of dynamicFormData.areaSettings; let i=index" class="col-12 group-item">
          <input type="radio" value="{{item.value}}" name="areaSettings" formControlName="areaSettings">
          <span class="pe-2 font-weight-500">{{item.title}}</span>
          <hr *ngIf="i+1<dynamicFormData.areaSettings.length">
        </label>
      </div>

      <div class="mt-4 p-4">
        <span>יש חניה</span>
        <label class="switch float-left">
          <input type="checkbox" formControlName="parking">
          <span class="slider round"></span>
        </label>
        <hr>
        <span>נגיש</span>
        <label class="switch float-left">
          <input type="checkbox" formControlName="accessible">
          <span class="slider round"></span>
        </label>
        <hr>
        <span>ידידותי לבעלי חיים</span>
        <label class="switch float-left">
          <input type="checkbox" formControlName="petFriendly">
          <span class="slider round"></span>
        </label>
        <hr>
        <span>אפשר להרעיש</span>
        <label class="switch float-left">
          <input type="checkbox" formControlName="makeNoise">
          <span class="slider round"></span>
        </label>
        <hr>
        <span>יש פסנתר</span>
        <label class="switch float-left">
          <input type="checkbox" formControlName="piano">
          <span class="slider round"></span>
        </label>
      </div>

      <div class="text-center mt-5">
        <button class="btn btn-submit mb-5 w-50 font-size-22" type="submit">סנן</button>
      </div>
    </form>
  </div>
</div>
