import {Component, OnDestroy, OnInit} from '@angular/core';
import {Festival} from '../shared/models/festival.model';
import {ActivatedRoute} from '@angular/router';
import {FestivalService} from '../shared/services/festival.service';
import {Subscription} from 'rxjs';
import {DataService} from '../shared/services/data.service';
import {Event} from '../shared/models/event.model';
import {faTrash} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-my-events',
  templateUrl: './my-events.component.html',
  styleUrls: [
    '../shared/styles/list.css',
    './my-events.component.css'
  ]
})
export class MyEventsComponent implements OnInit, OnDestroy {
  festival: Festival;
  festivalSubscription: Subscription;
  myEventsSubscription: Subscription;
  events: Event[];
  eventsSubscription: Subscription;
  deleteSubscription: Subscription;
  faTrash = faTrash;

  constructor(
    private route: ActivatedRoute,
    private festivalService: FestivalService,
    private dataService: DataService) {
  }

  ngOnInit(): void {
    this.festivalSubscription = this.festivalService.festivalsSubject.subscribe(festivals => {
      if (festivals) {
        const festivalEnglishName = this.route.snapshot.paramMap.get('festivalEnglishName');
        this.festival = this.festivalService.onGetCurrentFestival(festivalEnglishName);
        this.onGetMyEvents();
      }
    });
  }

  onGetMyEvents(): void {
    this.myEventsSubscription = this.dataService.getToMyEvents(this.festival.festivalEnglishName).subscribe(events => {
      this.events = events;
    });
  }

  getStatusTitle(statusID): string {
    let title;
    switch (statusID) {
      case 1:
        title = 'אושר';
        break;
    }
    return title;
  }

  delete(domEvent: any, item: Event): void {
    domEvent.stopPropagation();
    const confirmDelete = confirm('האם את/ה בטוח שאת/ה רוצה למחוק את האירוע? שים/י לב שגם השותף/ה מיודעים לכך.');
    if (confirmDelete === true) {
      this.deleteSubscription = this.dataService.deleteEvent(item.eventID).subscribe(() => {
        this.onGetMyEvents();
      });
    }
  }

  ngOnDestroy(): void {
    if (this.festivalSubscription) {
      this.festivalSubscription.unsubscribe();
    }
    if (this.myEventsSubscription) {
      this.festivalSubscription.unsubscribe();
    }
    if (this.eventsSubscription) {
      this.eventsSubscription.unsubscribe();
    }
    if (this.deleteSubscription) {
      this.deleteSubscription.unsubscribe();
    }
    this.festival = null;
    this.events = [];
  }
}
