<div class="container-fluid mb-10 ps-0 pe-0" *ngIf="festival">
  <div class="d-flex flex-wrap page-navbar">
    <div class="col-2">
      <app-my-messages-image></app-my-messages-image>
    </div>
    <div class="col-8">{{festival.name}}</div>
    <app-navbar-menu></app-navbar-menu>
    <div class="col-12 text-center font-size-24 font-weight-500">
      <span>מקומות</span>
    </div>
  </div>

  <div class="desktop-content-box">
    <!-- filters boxes -->
    <div class="d-flex mt-4 me-1 ms-1">
      <div *ngIf="filteredAreaSettings" class="col-4 filter-box">
        <span>{{filteredAreaSettings.title}}</span>
        <fa-icon class="remove-filter" [icon]="faWindowClose" (click)="removeFilter('areaSetting')"></fa-icon>
      </div>
      <div *ngIf="filteredNeighborhood" class="col-4 filter-box">
        <span>{{filteredNeighborhood.title}}</span>
        <fa-icon class="remove-filter" [icon]="faWindowClose" (click)="removeFilter('neighborhood')"></fa-icon>
      </div>
      <div *ngIf="filteredNumberOfParticipants" class="col-6 filter-box">
        <span>{{filteredNumberOfParticipants}}</span>
        <fa-icon class="remove-filter" [icon]="faWindowClose" (click)="removeFilter('numberOfParticipants')"></fa-icon>
      </div>
      <div *ngIf="filterByParking" class="col-4 filter-box">
        <span>יש חניה</span>
        <fa-icon class="remove-filter" [icon]="faWindowClose" (click)="removeFilter('parking')"></fa-icon>
      </div>
      <div *ngIf="filterByAccessible" class="col-4 filter-box">
        <span>נגיש</span>
        <fa-icon class="remove-filter" [icon]="faWindowClose" (click)="removeFilter('accessible')"></fa-icon>
      </div>
      <div *ngIf="filterByPetFriendly" class="col-6 filter-box">
        <span>ידידותי לבעלי חיים</span>
        <fa-icon class="remove-filter" [icon]="faWindowClose" (click)="removeFilter('petFriendly')"></fa-icon>
      </div>
      <div *ngIf="filterByMakeNoise" class="col-6 filter-box">
        <span>אפשר להרעיש</span>
        <fa-icon class="remove-filter" [icon]="faWindowClose" (click)="removeFilter('makeNoise')"></fa-icon>
      </div>
      <div *ngIf="filterByPiano" class="col-6 filter-box">
        <span>יש פסנתר</span>
        <fa-icon class="remove-filter" [icon]="faWindowClose" (click)="removeFilter('piano')"></fa-icon>
      </div>
    </div>
  </div>

  <div class="container-fluid mb-7 ps-4 pe-4">
    <div class="row">
      <div id="{{place.placeID}}" class="col-sm-12 col-md-2 col-lg-3 pointer initiative-wrapper mb-4 text-center"
           *ngFor="let place of places" (click)="onNavigateToPlace(place.placeID)"
           [class.hidden]="place.numberOfEventsAtPlace===place.numberOfInitiativesWillingToHost">
        <div class="item-box text-center pointer">
          <div class="p-3 text-right">
            <div class="font-size-18 font-weight-500 color-light-peach">{{place.description}}</div>
            <div class="font-size-17 font-weight-400 color-text-info">
              <span>אירוח: </span>
              <span>{{place.host}}</span>
            </div>
          </div>
          <div class="event-image" [style.backgroundImage]="'url('+ place.path +')'"></div>

          <div class="d-flex align-items-center mt-3 pe-2">
            <div>
              <img src="assets/location.svg" alt="אייקון של מקום" class="location-icon">
            </div>
            <a href="https://www.google.com/maps?q={{place.lat}},{{place.lng}}" target="_blank">
              <div class="font-size-16 font-weight-400">{{place.street}} {{place.houseNumber}}</div>
            </a>
          </div>

          <div class="d-flex align-items-center mt-3 pe-2">
            <div>
              <img src="assets/user.svg" alt="אייקון של אורח" class="location-icon">
            </div>
            <div class="font-size-16 font-weight-400">
              <span>עד </span>
              <span>{{place.maxNumberOfPeople}}</span>
              <span> אנשים</span>
            </div>
          </div>

          <div class="d-flex align-items-center mt-3 pe-2">
            <div>
              <img src="assets/initiative.svg" alt="אייקון של יוזמה" class="location-icon">
            </div>
            <div class="font-size-16 font-weight-400">
              <span>{{place.numberOfEventsAtPlace}}/{{place.numberOfInitiativesWillingToHost}}</span>
              <span> יוזמות כבר שודכו למקום</span>
            </div>
          </div>

          <div class="link-wrapper pt-3 pb-3">
            <div class="d-flex justify-content-end pt-4 pb-3 ps-3">
              <div class="item-link font-size-14">נשמע שיכול להיות חיבור טוב</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <!--  <div class="circle pointer">
      <img id="filter-icon" src="assets/Icons_Filter.svg" alt="תמונה של פילטר" routerLink="/festival/{{festival.festivalEnglishName}}/filter-places-list">
    </div>-->
  <app-footer-navbar></app-footer-navbar>
</div>

