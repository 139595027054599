import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {FestivalService} from '../shared/services/festival.service';
import {Festival} from '../shared/models/festival.model';

@Component({
  selector: 'app-footer-navbar',
  templateUrl: './footer-navbar.component.html',
  styleUrls: ['./footer-navbar.component.css']
})
export class FooterNavbarComponent implements OnInit {

  festivalEnglishName: string;

  constructor(
    private route: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.festivalEnglishName = this.route.snapshot.paramMap.get('festivalEnglishName');
  }

}
