<div class="d-flex page-navbar w-100">
  <div class="col-10 text-right font-size-17 font-weight-500">{{title}}</div>
  <app-navbar-menu></app-navbar-menu>
  <div class="col-12" *ngIf="listLink && mapLink">
    <div class="d-flex font-size-16 text-center">
      <div class="col-12">
        <a class="textual-link color-white" routerLink="{{listLink}}">רשימה</a>
      </div>
      <!--<div class="col-6">
        <a class="textual-link color-white" routerLink="{{mapLink}}">מפה</a>
      </div>-->
    </div>
  </div>
</div>
