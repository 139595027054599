import {Component, OnDestroy, OnInit} from '@angular/core';
import {Festival} from '../shared/models/festival.model';
import {HttpClient} from '@angular/common/http';
import {FestivalService} from '../shared/services/festival.service';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {environment} from '../../environments/environment';
import {AddInitiative1DynamicFormData} from './addInitiative1DynamicFormData.model';
import {ActivatedRoute, Router} from '@angular/router';
import {DataService} from '../shared/services/data.service';
import {faMinusCircle, faPlusCircle} from '@fortawesome/free-solid-svg-icons';
import {Subscription} from 'rxjs';
import * as moment from 'moment';

@Component({
  selector: 'app-add-initiative1',
  templateUrl: './add-initiative1.component.html',
  styleUrls: [
    '../shared/styles/add-initiative-shared.css',
    '../shared/styles/forms-style.css',
    './add-initiative1.component.css'
  ]
})
export class AddInitiative1Component implements OnInit, OnDestroy {
  localStorageKey = 'addInitiativeFormData';
  currentStep = 1;
  festival: Festival;
  festivalsSubscription: Subscription;
  addInitiativeForm: UntypedFormGroup;
  addInitiativeFormSubmitted: boolean;
  festivals: Festival[];
  numberOfParticipants = 0;
  minimumParticipants = 1;
  maximumParticipants = environment.maximumNumberOfParticipant;
  dynamicFormData: AddInitiative1DynamicFormData;
  dynamicFormDataSubscription: Subscription;
  faMinusCircle = faMinusCircle;
  faPlusCircle = faPlusCircle;

  dateRange = [];
  selectedDate;
  selectedHour;
  numberOfInitiatives = 1;
  hours = this.dataService.hours;
  data = {};

  numberOfSelectedAvailableHours = 0;

  constructor(
    private http: HttpClient,
    private festivalService: FestivalService,
    private dataService: DataService,
    private route: ActivatedRoute,
    private router: Router) {
  }

  ngOnInit(): void {
    this.festivalsSubscription = this.festivalService.festivalsSubject.subscribe(festivals => {
      if (festivals) {
        this.festivals = festivals;
        const festivalEnglishName = this.route.snapshot.paramMap.get('festivalEnglishName');
        this.festival = this.festivalService.onGetCurrentFestival(festivalEnglishName);

        for (const m = moment(this.festival.fromDate); m.diff(this.festival.untilDate, 'days') <= 0; m.add(1, 'days')) {
          const fullDate = m.format('YYYY-MM-DD');
          this.setDataObject(fullDate);
          this.dateRange.push({
            day: m.locale('he').format('dddd'),
            dayMonth: m.format('DD/MM'),
            fullDate
          });
        }

        this.buildForm();
        this.selectDate(this.festival.fromDate);
        this.selectDate(this.festival.fromDate);
        this.loadFormWithDataFromLocalStorage();
      }
    });

    this.dynamicFormDataSubscription = this.http.get<AddInitiative1DynamicFormData>(`${environment.apiURL}/data/add-initiative1`).subscribe(res => {
      this.dynamicFormData = res;
    });
  }

  buildForm(): void {
    this.addInitiativeForm = new UntypedFormGroup({
      name: new UntypedFormControl('', Validators.required),
      description: new UntypedFormControl('', Validators.required),
      details: new UntypedFormControl('', Validators.required),
      duration: new UntypedFormControl(0, Validators.required),
      public: new UntypedFormControl('', Validators.required),
      areaSettings: new UntypedFormControl('', Validators.required),
      numberOfParticipants: new UntypedFormControl('', Validators.required),
      virtual: new UntypedFormControl(''),
      date: new UntypedFormControl(''),
      hour: new UntypedFormControl('')
    });
  }

  loadFormWithDataFromLocalStorage(): void {
    const localStorageData = this.dataService.getLocalStorageData(this.localStorageKey);
    if (localStorageData) {
      const data = localStorageData[this.currentStep];
      if (data) {
        const vars = ['name', 'description', 'details', 'public', 'areaSettings', 'duration', 'virtual', 'date', 'hour'];
        for (const item of vars) {
          if (data[item]) {
            this.addInitiativeForm.controls[item].setValue(data[item]);
          }
        }
        if (data.numberOfParticipants) {
          this.numberOfParticipants = data.numberOfParticipants;
        }
        if (data.date && data.hour) {
          this.selectedDate = data.date;
          this.selectHour(data.hour);
        }
      }
    }
  }

  setDataObject(dateString): void {
    this.data[dateString] = {hours: []};
  }

  selectDate(date): void {
    this.selectedDate = date;
  }

  addDuration(): void {
    const currentDuration = this.addInitiativeForm.value.duration;
    this.addInitiativeForm.controls.duration.setValue(currentDuration + 30);
  }

  decreaseDuration(): void {
    const currentDuration = this.addInitiativeForm.value.duration;
    if (currentDuration > 0) {
      this.addInitiativeForm.controls.duration.setValue(currentDuration - 30);
    }
  }

  getNumberOfSelectedHours(): void {
    let hours = 0;
    for (const date of Object.keys(this.data)) {
      hours += this.data[date].hours.length;
    }
    this.numberOfSelectedAvailableHours = hours;
  }

  onSubmit(): void {
    this.addInitiativeForm.controls.numberOfParticipants.setValue(this.numberOfParticipants);
    this.addInitiativeFormSubmitted = true;
    if (this.addInitiativeForm.status === 'VALID' &&
      this.addInitiativeForm.value.duration > 0 &&
      this.numberOfParticipants > this.minimumParticipants &&
      this.numberOfParticipants <= this.maximumParticipants) {

      this.getNumberOfSelectedHours();

      if (this.addInitiativeForm.value.virtual) {
        this.addInitiativeForm.controls.date.setValue(this.selectedDate);
        this.addInitiativeForm.controls.hour.setValue(this.data[this.selectedDate].hours[0]);
        if (this.numberOfSelectedAvailableHours !== 1) {
          console.log('error');
          return;
        }
      } else {
        if (this.selectedDate) {
          this.addInitiativeForm.controls.date.reset();
          this.addInitiativeForm.controls.hour.reset();
        }
      }
      this.dataService.addFormData(this.localStorageKey, this.currentStep, this.addInitiativeForm.value);
      this.router.navigate([`/festival/${this.festival.festivalEnglishName}/add-initiative/2`]);
    }
  }

  selectHour(hour): void {
    this.resetHours();
    this.data[this.selectedDate].hours.push(hour);
    this.getNumberOfSelectedHours();
  }

  resetHours(): void {
    for (const date of Object.keys(this.data)) {
      this.data[date].hours = [];
    }
  }

  isHourSelected(hour): boolean {
    if (this.data[this.selectedDate]) {
      if (this.data[this.selectedDate].hours.includes(hour)) {
        return true;
      }
    }
    return false;
  }

  ngOnDestroy(): void {
    this.festivalsSubscription.unsubscribe();
    this.dynamicFormDataSubscription.unsubscribe();
  }
}
