<div class="container-fluid p-0" *ngIf="festival">
  <app-navbar-title-subtitle-step-back [festival]="festival" title="יצירת מקום" subTitle="טופס רצינות" step="5" totalSteps="5"></app-navbar-title-subtitle-step-back>
  <div class="container-fluid h-100 festivals-box desktop-content-box">
    <div class="d-flex mt-4 mb-2">
      <div class="col">
        הריני מאשר/ת כי אני משתתפת/מארח באירועים של
        <span> {{festival.name}} </span>
        על אחריותי בלבד, ואין לי ולא יהיו לי תלונות או תביעות כלפי מארגנות ומארגני הפסטיבל בכל הקשור במישרין או בעקיפין בהשתתפותי באירועים.
        למארחים- הריני מאשר קיומו של ביטוח מתאים למבנה כולל ביטוח צד ג'.
      </div>
    </div>

    <div class="checkbox-group d-flex col mb-4">
      <label class="checkbox-option">
        <input type="checkbox" [(ngModel)]="agree">
        <span class="checkbox-label font-size-17 font-weight-400 me-2">קראתי ומקובל עליי</span>
      </label>
    </div>

    <div class="d-flex mb-5 justify-content-center">
      <button id="submit-btn" class="btn btn-submit font-size-18 pe-3 ps-3" type="button" (click)="onSubmit(placeCreatedModal)">ליצירת המקום</button>
    </div>
  </div>
  <app-footer-navbar *ngIf="showFooter"></app-footer-navbar>
</div>

<ng-template #placeCreatedModal let-modal>
  <div class="modal-header flex-column border-bottom-0 row justify-content-center mt-4">
    <img class="success-icon" src="assets/Icons_Success.svg" alt="תמונה של קונפטי">
  </div>
  <div class="modal-header justify-content-center">
    <h4 class="modal-title">המקום שלכם נוצר!</h4>
  </div>
  <div class="modal-body bg-text-light-blue color-white">
    שימו לב שעד שלא יהיה אישור הדדי בין היוזמה לבין המקום שלכם, לא יווצר אירוע
  </div>
  <div class="modal-footer mt-4 mb-3 border-top-0 justify-content-center">
    <button class="col-11 btn btn-submit font-size-22 mb-3" type="submit" routerLink="/festival/{{festival.festivalEnglishName}}/initiatives-list" (click)="modal.close()">אני רוצה לחפש יוזמה</button>
    <button class="col-11 btn btn-dismiss font-size-18 pe-3 mb-3 ps-3" type="button" routerLink="/festival/{{festival.festivalEnglishName}}/add-initiative/1" (click)="modal.close()">יש לי גם יוזמה להציע</button>
    <button class="col-11 btn btn-dismiss font-size-18 pe-3 ps-3 mb-3" type="button" routerLink="/festival/{{festival.festivalEnglishName}}//options" (click)="modal.close()">חזרה לדף הבית</button>
  </div>
</ng-template>
