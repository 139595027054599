<div class="container-fluid mb-10 ps-0 pe-0" *ngIf="festival">
  <app-my-area-navbar></app-my-area-navbar>
  <div class="mb-7 desktop-content-box">
    <div *ngIf="events && events.length===0" class="no-data-notice-box">
      <div class="font-size-21 text-center w-100">טרם יצרת אירוע</div>
    </div>

    <div class="image-container mt-4 text-center" *ngFor="let item of events" routerLink="/festival/{{festival.festivalEnglishName}}/edit-event/details/{{item.eventID}}">
      <div class="text-right">
        <div class="image-text-box">
          <div class="font-size-21 font-weight-bold">{{item.title}}</div>
          <div class="font-size-16 status" *ngIf="item.street">
            <span>מקום אירוח: </span>
            <span>{{item.street}} </span>
            <span *ngIf="item.houseNumber">{{item.houseNumber}} </span>
            <span *ngIf="item.entrance">{{item.entrance}} </span>
          </div>
          <div class="font-size-16">
            <span>סטטוס: </span>
            <span>{{getStatusTitle(item.status)}}</span>
          </div>
        </div>
      </div>
      <img class="initiative-image" [src]="item.path" alt="תמונת אירוע">
      <div class="text-center mt-3 mb-3">
        <button class="btn btn-delete font-size-18 pe-3 ps-3 w-75" (click)="delete($event, item)" type="button">
          <fa-icon [icon]="faTrash" class="float-right"></fa-icon>
          <span>מחיקת האירוע</span>
        </button>
      </div>
    </div>
  </div>
  <app-footer-navbar></app-footer-navbar>
</div>

<!-- todo add number of registered users 5 from 15 -->
