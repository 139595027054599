<div class="container-fluid mb-10 p-0" *ngIf="festival && event">
  <app-navbar-edit-event [event]="event"></app-navbar-edit-event>
  <div class="mb-7">
    <form [formGroup]="editEventForm" (ngSubmit)="onSubmit()">
      <!-- Date and Hour -->
      <div class="d-flex mt-4 text-center font-size-17">
        <div class="col pb-3 select-date-box" *ngFor="let item of dateRange" (click)="selectDate(item.fullDate)" [class.selected-date]="item.fullDate===selectedDate">
          <div>{{item.day}}</div>
          <div>{{item.dayMonth}}</div>
        </div>
      </div>
      <div class="d-flex flex-wrap select-hours-box mt-4 justify-content-center ps-4 pe-4">
        <div class="hour me-2 mb-2 p-2"
             *ngFor="let item of hours"
             (click)="selectHour(item)"
             [class.selected-hour]="isHourSelected(item)">
          {{item}}
        </div>
      </div>
      <div class="text-center mt-5">
        <button class="btn btn-submit font-size-18 pe-3 ps-3" type="submit">עדכון זמן האירוע</button>
      </div>
    </form>
  </div>
  <app-footer-navbar></app-footer-navbar>
</div>
