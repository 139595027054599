import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../auth/auth.service';
import {DataService} from '../shared/services/data.service';
import {FestivalService} from '../shared/services/festival.service';
import {Festival} from '../shared/models/festival.model';
import {AddInitiative1DynamicFormData} from '../add-initiative1/addInitiative1DynamicFormData.model';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-add-place2',
  templateUrl: './add-place2.component.html',
  styleUrls: [
    '../shared/styles/add-initiative-shared.css',
    '../shared/styles/forms-style.css',
    './add-place2.component.css'
  ]
})
export class AddPlace2Component implements OnInit, OnDestroy {

  localStorageKey = 'addPlaceFormData';
  currentStep = 2;
  addPlaceForm: UntypedFormGroup;
  formSubmitted: boolean;
  festival: Festival;
  festivalSubscription: Subscription;
  dynamicFormDataSubscription: Subscription;
  maxNumberOfPeople = 0;
  minimumParticipants = 1;
  maximumParticipants = environment.maximumNumberOfParticipant;
  dynamicFormData: AddInitiative1DynamicFormData;

  constructor(
    private http: HttpClient,
    public router: Router,
    private authService: AuthService,
    private dataService: DataService,
    private festivalService: FestivalService,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.festivalSubscription = this.festivalService.festivalsSubject.subscribe(festivals => {
      if (festivals) {
        const festivalEnglishName = this.route.snapshot.paramMap.get('festivalEnglishName');
        this.festival = this.festivalService.onGetCurrentFestival(festivalEnglishName);
      }
    });

    this.dynamicFormDataSubscription = this.http.get<AddInitiative1DynamicFormData>(`${environment.apiURL}/data/add-initiative1`).subscribe(res => {
      this.dynamicFormData = res;
    });

    this.addPlaceForm = new UntypedFormGroup({
      areaSettings: new UntypedFormControl('', Validators.required),
      parking: new UntypedFormControl(false),
      accessible: new UntypedFormControl(false),
      petFriendly: new UntypedFormControl(false),
      makeNoise: new UntypedFormControl(false),
      piano: new UntypedFormControl(false),
      notice: new UntypedFormControl(''),
      maxNumberOfPeople: new UntypedFormControl('')
    });

    this.loadFormWithDataFromLocalStorage();
  }

  loadFormWithDataFromLocalStorage(): void {
    const localStorageData = this.dataService.getLocalStorageData(this.localStorageKey);
    if (localStorageData) {
      const data = localStorageData[this.currentStep];
      const vars = ['areaSettings', 'parking', 'accessible', 'petFriendly', 'makeNoise', 'piano', 'notice'];
      if (data) {
        for (const item of vars) {
          if (data[item]) {
            this.addPlaceForm.controls[item].setValue(data[item]);
          }
        }
        if (data.maxNumberOfPeople) {
          this.maxNumberOfPeople = data.maxNumberOfPeople;
        }
      }
    }
  }

  onSubmit(): void {
    this.formSubmitted = true;
    this.addPlaceForm.controls.maxNumberOfPeople.setValue(this.maxNumberOfPeople);
    if (this.addPlaceForm.status === 'VALID' &&
      this.maxNumberOfPeople > this.minimumParticipants &&
      this.maxNumberOfPeople <= this.maximumParticipants) {
      this.dataService.addFormData(this.localStorageKey, this.currentStep, this.addPlaceForm.value);
      this.router.navigate([`/festival/${this.festival.festivalEnglishName}/add-place/3`]);
    }
  }

  ngOnDestroy(): void {
    this.festivalSubscription.unsubscribe();
    this.dynamicFormDataSubscription.unsubscribe();
  }
}
