import {Component, OnDestroy, OnInit} from '@angular/core';
import {Festival} from '../shared/models/festival.model';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {FestivalService} from '../shared/services/festival.service';
import {DataService} from '../shared/services/data.service';
import {Event} from '../shared/models/event.model';

@Component({
  selector: 'app-events-list',
  templateUrl: './events-list.component.html',
  styleUrls: [
    '../shared/styles/list.css',
    './events-list.component.css'
  ]
})
export class EventsListComponent implements OnInit, OnDestroy {

  festival: Festival;
  festivalSubscription: Subscription;
  events: Event[];
  eventsSubscription: Subscription;

  constructor(private route: ActivatedRoute, private festivalService: FestivalService, private dataService: DataService) {
  }

  ngOnInit(): void {
    this.festivalSubscription = this.festivalService.festivalsSubject.subscribe(festivals => {
      if (festivals) {
        const festivalEnglishName = this.route.snapshot.paramMap.get('festivalEnglishName');
        this.festival = this.festivalService.onGetCurrentFestival(festivalEnglishName);
        this.dataService.subscribeToEvents(festivalEnglishName);

        this.eventsSubscription = this.dataService.eventsSubject.subscribe(events => {
          if (events) {
            this.events = events;
          }
        });
      }
    });
  }

  getStatusTitle(statusID): void {
    let title;
    switch (statusID) {
      case 1:
        title = 'אושר';
        break;
    }
    return title;
  }

  ngOnDestroy(): void {
    this.festivalSubscription.unsubscribe();
    this.eventsSubscription.unsubscribe();
  }

}
