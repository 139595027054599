import {AfterViewChecked, Component, OnDestroy, OnInit} from '@angular/core';
import {Festival} from '../shared/models/festival.model';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {FestivalService} from '../shared/services/festival.service';
import {DataService} from '../shared/services/data.service';
import {Place} from '../shared/models/place.model';
import {Subscription} from 'rxjs';
import {faWindowClose} from '@fortawesome/free-solid-svg-icons';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {FilterPlacesDynamicData} from '../filter-places-list/filterPlacesDynamicData.model';

@Component({
  selector: 'app-places-list',
  templateUrl: './places-list.component.html',
  styleUrls: [
    '../shared/styles/list.css',
    './places-list.component.css'
  ]
})
export class PlacesListComponent implements OnInit, AfterViewChecked, OnDestroy {

  festival: Festival;
  festivalSubscription: Subscription;
  routeSubscription: Subscription;
  places: Place[];
  isScrollExecuted = false;

  filters;
  filterByAreaSettings;
  filteredAreaSettings;

  filterByNeighborhood;
  filteredNeighborhood;

  filterByNumberOfParticipants;
  filteredNumberOfParticipants;

  filterByParking;
  filteredParking;

  filterByAccessible;
  filteredAccessible;

  filterByPetFriendly;
  filteredPetFriendly;

  filterByMakeNoise;
  filteredMakeNoise;

  filterByPiano;
  filteredPiano;

  faWindowClose = faWindowClose;

  constructor(
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private festivalService: FestivalService,
    private dataService: DataService
  ) {
  }

  ngOnInit(): void {
    this.festivalSubscription = this.festivalService.festivalsSubject.subscribe(festivals => {
      if (festivals) {
        const festivalEnglishName = this.route.snapshot.paramMap.get('festivalEnglishName');
        this.festival = this.festivalService.onGetCurrentFestival(festivalEnglishName);
        this.buildData();
      }
    });

    // listen to route changes - used to apply filters
    this.routeSubscription = this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.buildData();
      }
    });
  }

  ngAfterViewChecked(): void {
    if (this.places && this.places.length > 0 && !this.isScrollExecuted) {
      this.scrollToLastViewedEventInList();
    }
  }

  private scrollToLastViewedEventInList(): void {
    const lastClickedEventID = localStorage.getItem('lastClickedPlaceID');

    if (lastClickedEventID && !this.isScrollExecuted) {
      setTimeout(() => {
        const element = document.getElementById(lastClickedEventID);
        if (element) {
          element.scrollIntoView({});
          this.isScrollExecuted = true;
          localStorage.removeItem('lastClickedPlaceID');
        }
      }, 100);
    }
  }

  buildData(): void {
    this.buildFilters();
    this.getPlaces();
  }

  buildFilters(): void {
    // @ts-ignore
    const filterByAreaSettings = this.route.snapshot.paramMap.params.areaSettings;
    this.filterByAreaSettings = filterByAreaSettings;
    // @ts-ignore
    const filterByNeighborhood = this.route.snapshot.paramMap.params.neighborhood;
    this.filterByNeighborhood = filterByNeighborhood;
    // @ts-ignore
    const filterByNumberOfParticipants = this.route.snapshot.paramMap.params.numberOfParticipants;
    this.filterByNumberOfParticipants = filterByNumberOfParticipants;
    // @ts-ignore
    const filterByParking = this.route.snapshot.paramMap.params.parking;
    this.filterByParking = filterByParking;
    // @ts-ignore
    const filterByAccessible = this.route.snapshot.paramMap.params.accessible;
    this.filterByAccessible = filterByAccessible;
    // @ts-ignore
    const filterByPetFriendly = this.route.snapshot.paramMap.params.petFriendly;
    this.filterByPetFriendly = filterByPetFriendly;
    // @ts-ignore
    const filterByMakeNoise = this.route.snapshot.paramMap.params.makeNoise;
    this.filterByMakeNoise = filterByMakeNoise;
    // @ts-ignore
    const filterByPiano = this.route.snapshot.paramMap.params.piano;
    this.filterByPiano = filterByPiano;

    this.filters = {
      filterByAreaSettings,
      filterByNeighborhood,
      filterByNumberOfParticipants,
      filterByParking,
      filterByAccessible,
      filterByPetFriendly,
      filterByMakeNoise,
      filterByPiano,
    };

    if (this.filterByAreaSettings || this.filterByNeighborhood) {
      this.http.get(`${environment.apiURL}/data/filter-places?festival=${this.festival.festivalEnglishName}`)
        .subscribe((res: FilterPlacesDynamicData) => {
          // area settings
          if (this.filterByAreaSettings) {
            this.filteredAreaSettings = res.areaSettings.find(item => item.value === this.filterByAreaSettings);
          }
          // neighborhood
          if (this.filterByNeighborhood) {
            this.filteredNeighborhood = res.neighborhoods.find(item => item.neighborhoodID === this.filterByNeighborhood);
          }
        });
    }
    // number of participants
    if (this.filterByNumberOfParticipants) {
      this.filteredNumberOfParticipants = `עד ${this.filterByNumberOfParticipants} משתתפים`;
    }
  }

  getPlaces(): void {
    this.dataService.getPlace(this.festival.festivalEnglishName, null, this.filters).subscribe((places: Place[]) => {
      if (places) {
        this.places = places;
      }
    });
  }

  removeFilter(removeFilter): void {
    const filterObject = {
      areaSettings: undefined,
      neighborhood: undefined,
      numberOfParticipants: undefined,
      parking: undefined,
      accessible: undefined,
      petFriendly: undefined,
      makeNoise: undefined,
      piano: undefined,
    };
    if (this.filterByAreaSettings) {
      filterObject.areaSettings = this.filterByAreaSettings;
    }
    if (this.filterByNeighborhood) {
      filterObject.neighborhood = this.filterByNeighborhood;
    }
    if (this.filterByNumberOfParticipants) {
      filterObject.numberOfParticipants = this.filterByNumberOfParticipants;
    }
    if (this.filterByParking) {
      filterObject.parking = this.filterByParking;
    }
    if (this.filterByAccessible) {
      filterObject.accessible = this.filterByAccessible;
    }
    if (this.filterByPetFriendly) {
      filterObject.petFriendly = this.filterByPetFriendly;
    }
    if (this.filterByMakeNoise) {
      filterObject.makeNoise = this.filterByMakeNoise;
    }
    if (this.filterByPiano) {
      filterObject.piano = this.filterByPiano;
    }
    switch (removeFilter) {
      case 'areaSetting':
        this.filterByAreaSettings = null;
        this.filteredAreaSettings = null;
        delete filterObject.areaSettings;
        break;
      case 'neighborhood':
        this.filterByNeighborhood = null;
        this.filteredNeighborhood = null;
        delete filterObject.neighborhood;
        break;
      case 'numberOfParticipants':
        this.filterByNumberOfParticipants = null;
        this.filteredNumberOfParticipants = null;
        delete filterObject.numberOfParticipants;
        break;
      case 'parking':
        this.filterByParking = null;
        this.filteredParking = null;
        delete filterObject.parking;
        break;
      case 'accessible':
        this.filterByAccessible = null;
        this.filteredAccessible = null;
        delete filterObject.accessible;
        break;
      case 'petFriendly':
        this.filterByPetFriendly = null;
        this.filteredPetFriendly = null;
        delete filterObject.petFriendly;
        break;
      case 'makeNoise':
        this.filterByMakeNoise = null;
        this.filteredMakeNoise = null;
        delete filterObject.makeNoise;
        break;
      case 'piano':
        this.filterByPiano = null;
        this.filteredPiano = null;
        delete filterObject.piano;
        break;
    }
    // remove undefined values from filter object
    Object.keys(filterObject).forEach(key => filterObject[key] === undefined && delete filterObject[key]);
    this.router.navigate([`/festival/${this.festival.festivalEnglishName}/places-list`, filterObject]);
  }

  onNavigateToPlace(placeID): void {
    // Save the clicked eventID or scroll position
    localStorage.setItem('lastClickedPlaceID', placeID);

    // Navigate to the event-page
    this.router.navigate([`/festival/${this.festival.festivalEnglishName}/place/`, placeID]);
  }


  ngOnDestroy(): void {
    this.festivalSubscription.unsubscribe();
    this.routeSubscription.unsubscribe();
  }
}
