import {Component, OnDestroy, OnInit} from '@angular/core';
import {Festival} from '../shared/models/festival.model';
import {Subscription} from 'rxjs';
import {Event} from '../shared/models/event.model';
import {ActivatedRoute} from '@angular/router';
import {FestivalService} from '../shared/services/festival.service';
import {DataService} from '../shared/services/data.service';

@Component({
  selector: 'app-edit-event-participants',
  templateUrl: './edit-event-participants.component.html',
  styleUrls: ['./edit-event-participants.component.css']
})
export class EditEventParticipantsComponent implements OnInit, OnDestroy {

  festival: Festival;
  festivalSubscription: Subscription;
  eventSubscription: Subscription;
  event: Event;

  constructor(private route: ActivatedRoute, private festivalService: FestivalService, private dataService: DataService) {
  }

  ngOnInit(): void {
    this.festivalSubscription = this.festivalService.festivalsSubject.subscribe(festivals => {
      if (festivals) {
        const festivalEnglishName = this.route.snapshot.paramMap.get('festivalEnglishName');
        this.festival = this.festivalService.onGetCurrentFestival(festivalEnglishName);
        const eventID = this.route.snapshot.paramMap.get('eventID');
        this.eventSubscription = this.dataService.getEvent(festivalEnglishName, eventID).subscribe((events: Event[]) => {
          this.event = events[0];
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.festivalSubscription.unsubscribe();
  }

}
