import {Component, OnDestroy, OnInit} from '@angular/core';
import * as moment from 'moment';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../auth/auth.service';
import {DataService} from '../shared/services/data.service';
import {FestivalService} from '../shared/services/festival.service';
import {Festival} from '../shared/models/festival.model';
import {faMinusCircle, faPlusCircle} from '@fortawesome/free-solid-svg-icons';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-add-place4',
  templateUrl: './add-place4.component.html',
  styleUrls: [
    '../shared/styles/add-initiative-shared.css',
    '../shared/styles/forms-style.css',
    './add-place4.component.css'
  ]
})
export class AddPlace4Component implements OnInit, OnDestroy {

  localStorageKey = 'addPlaceFormData';
  currentStep = 4;
  festival: Festival;
  festivalSubscription: Subscription;
  formattedFromDate;
  dateRange = [];
  selectedDate;
  selectedHour;
  numberOfInitiatives = 1;
  hours = this.dataService.hours;
  data = {};
  faMinusCircle = faMinusCircle;
  faPlusCircle = faPlusCircle;
  submitted: boolean;
  numberOfSelectedAvailableHours = 0;

  constructor(
    public router: Router,
    private authService: AuthService,
    private dataService: DataService,
    private festivalService: FestivalService,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.festivalSubscription = this.festivalService.festivalsSubject.subscribe(festivals => {
      if (festivals) {
        const festivalEnglishName = this.route.snapshot.paramMap.get('festivalEnglishName');
        this.festival = this.festivalService.onGetCurrentFestival(festivalEnglishName);
        this.selectedDate = moment(this.festival.fromDate);
        for (const m = moment(this.festival.fromDate); m.diff(this.festival.untilDate, 'days') <= 0; m.add(1, 'days')) {
          const fullDate = m.format('YYYY-MM-DD');
          this.setDataObject(fullDate);
          this.dateRange.push({
            day: m.locale('he').format('dddd'),
            dayMonth: m.format('DD/MM'),
            fullDate
          });
        }
        this.selectDate(this.festival.fromDate);
        this.loadFormWithDataFromLocalStorage();
      }
    });
  }

  loadFormWithDataFromLocalStorage(): void {
    const localStorageData = this.dataService.getLocalStorageData(this.localStorageKey);
    if (localStorageData) {
      const data = localStorageData[this.currentStep];
      if (data) {
        if (data.data) {
          this.data = data.data;
        }
        if (data.numberOfInitiatives) {
          this.numberOfInitiatives = data.numberOfInitiatives;
        }
      }
    }
  }

  setDataObject(dateString): void {
    this.data[dateString] = {hours: []};
  }

  selectDate(date): void {
    this.selectedDate = date;
  }

  getNumberOfSelectedHours(): void {
    let hours = 0;
    for (const date of Object.keys(this.data)) {
      hours += this.data[date].hours.length;
    }
    this.numberOfSelectedAvailableHours = hours;
  }

  onSubmit(): void {
    this.submitted = true;
    this.getNumberOfSelectedHours();

    if (this.numberOfSelectedAvailableHours > 0 && this.numberOfSelectedAvailableHours >= this.numberOfInitiatives) {
      const data = {
        data: this.data,
        numberOfInitiatives: this.numberOfInitiatives
      };
      this.dataService.addFormData(this.localStorageKey, this.currentStep, data);
      this.router.navigate([`/festival/${this.festival.festivalEnglishName}/add-place/5`]);
    }
  }

  selectHour(hour): void {
    if (this.data[this.selectedDate]) {
      if (this.data[this.selectedDate].hours.includes(hour)) {
        const indexOfExistingHour = this.data[this.selectedDate].hours.findIndex(item => item === hour);
        if (indexOfExistingHour > -1) {
          this.data[this.selectedDate].hours.splice(indexOfExistingHour, 1);
        }
      } else {
        this.data[this.selectedDate].hours.push(hour);
      }
    }
    this.getNumberOfSelectedHours();
  }

  isHourSelected(hour): boolean {
    if (this.data[this.selectedDate]) {
      if (this.data[this.selectedDate].hours.includes(hour)) {
        return true;
      }
    }
    return false;
  }

  addDuration(): void {
    this.numberOfInitiatives++;
  }

  decreaseDuration(): void {
    if (this.numberOfInitiatives > 1) {
      this.numberOfInitiatives--;
    }
  }

  ngOnDestroy(): void {
    this.festivalSubscription.unsubscribe();
  }

}
