import {Component, Input, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {faChevronRight} from '@fortawesome/free-solid-svg-icons';
import {Router} from '@angular/router';

@Component({
  selector: 'app-previous-page-icon',
  templateUrl: './previous-page-icon.component.html',
  styleUrls: ['./previous-page-icon.component.css']
})
export class PreviousPageIconComponent implements OnInit {

  @Input() url: string;
  faChevronRight = faChevronRight;

  constructor(
    private router: Router,
    private location: Location
  ) {
  }

  ngOnInit(): void {
  }

  goToPreviousPage(): void {
    if (this.url) {
      this.router.navigate([this.url]);
    } else {
      this.location.back();
    }
  }
}
