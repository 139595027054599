import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class FormatDataService {

  constructor() { }

  formatAsDay(date: string): string {
    const m = moment(date);
    return m.locale('he').format('dddd');
  }
}
