import {Component, Input, OnInit} from '@angular/core';
import {Festival} from '../shared/models/festival.model';

@Component({
    selector: 'app-navbar-title-subtitle-step-back',
    templateUrl: './navbar-title-subtitle-step-back.component.html',
    styleUrls: ['./navbar-title-subtitle-step-back.component.css']
})
export class NavbarTitleSubtitleStepBackComponent implements OnInit {

    @Input() festival: Festival;
    @Input() title: string;
    @Input() subTitle: string;
    @Input() step: number;
    @Input() totalSteps: number;

    progressBarWidth: string;

    constructor() {
    }

    ngOnInit(): void {
        this.progressBarWidth = `${100 / this.totalSteps * this.step}%`;
    }
}
