import {Component, OnDestroy, OnInit} from '@angular/core';
import {FestivalService} from '../shared/services/festival.service';
import {Subscription} from 'rxjs';
import {Festival} from '../shared/models/festival.model';
import {MessageService} from '../shared/services/message.service';
import {Message} from '../shared/models/message.model';

@Component({
  selector: 'app-my-messages-image',
  templateUrl: './my-messages-image.component.html',
  styleUrls: ['./my-messages-image.component.css']
})
export class MyMessagesImageComponent implements OnInit, OnDestroy {

  festival: Festival;
  messages: Message[];
  unreadMessages: Message[];
  messagesSubscription: Subscription;
  festivalSubscription: Subscription;
  festivalIsOverSubscription: Subscription;
  festivalIsOver: boolean;

  constructor(
    private festivalService: FestivalService,
    private messageService: MessageService
  ) {
  }

  ngOnInit(): void {
    this.festivalSubscription = this.festivalService.currentFestivalSubject.subscribe(festival => {
      if (festival) {
        this.festival = festival;
        this.messageService.getMyMessages(this.festival.festivalEnglishName);
        this.festivalService.checkIfFestivalIsOver(festival);
        this.festivalIsOverSubscription = this.festivalService.festivalIsOverSubject.subscribe((isOver: boolean) => {
          this.festivalIsOver = isOver;
        });
      }
    });

    this.messagesSubscription = this.messageService.messagesSubject.subscribe(messages => {
      if (messages) {
        this.messages = messages;
        this.unreadMessages = this.messages.filter(item => item.incoming && !item.messageRead);
      }
    });
  }

  ngOnDestroy(): void {
    this.festivalSubscription.unsubscribe();
    this.messagesSubscription.unsubscribe();
    this.festivalIsOverSubscription.unsubscribe();
  }

}
