<div class="container-fluid mb-10 ps-0 pe-0" *ngIf="festival">
  <app-my-area-navbar></app-my-area-navbar>
  <div class="mb-7 desktop-content-box">
    <div *ngIf="dataLoaded && places.length===0" class="no-data-notice-box">
      <div class="font-size-21 text-center w-100">טרם הוספת מקום</div>
    </div>
    <div class="image-container mt-4 text-center" *ngFor="let place of places" routerLink="/festival/{{festival.festivalEnglishName}}/edit-place/{{place.placeID}}">
      <div class="text-right">
        <div class="image-text-box">
          <div class="font-size-21 font-weight-bold">{{place.description}}</div>

          <div class="font-size-16 status" *ngIf="place.numberOfEventsAtPlace===0">
            <span>מחכה ליוזמה</span>
          </div>
          <div class="font-size-16 status" *ngIf="place.numberOfEventsAtPlace===1 && place.numberOfInitiativesWillingToHost===1">
            <span>סטטוס: מאושר ויש אירוע</span>
          </div>

          <div class="font-size-16 status" *ngIf="place.numberOfInitiativesWillingToHost>1">
            <span>{{place.numberOfEventsAtPlace}}/{{place.numberOfInitiativesWillingToHost}}</span>
            <span> יוזמות כבר שודכו למקום</span>
          </div>
        </div>
      </div>
      <img class="initiative-image" [src]="place.path" alt="תמונת מקום">
    </div>
  </div>
  <app-footer-navbar></app-footer-navbar>
</div>
