<div class="container-fluid vh-100 ps-0 pe-0">
  <div class="h-8"></div>
  <div class="h-50">
    <div class="d-flex">
      <h1 class="col">מפגשי קהילה</h1>
    </div>
  </div>
  <div class="h-25"></div>
  <div class="d-flex justify-content-around">
    <button type="button" routerLink="login" class="btn btn-lg col-5 bg-primary color-primary-text">כניסה</button>
    <button type="button" routerLink="register" class="btn btn-lg col-5 bg-white bg-surface-tonal color-primary">הרשמה</button>
  </div>
</div>
