import {Component, OnDestroy, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute} from '@angular/router';
import {Festival} from '../shared/models/festival.model';
import {FestivalService} from '../shared/services/festival.service';
import * as moment from 'moment';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-festival-page',
  templateUrl: './festival-page.component.html',
  styleUrls: ['./festival-page.component.css']
})
export class FestivalPageComponent implements OnInit, OnDestroy {

  festivalEnglishName;
  festival: Festival;
  festivalsSubscription: Subscription;
  timeUntilFestival: { days, hours, minutes, seconds };
  timeCounter;
  formattedFromDate;
  formattedUntilDate;
  isTodayBetweenFestivalDates: boolean;
  isTodayBeforeFestivalDates: boolean;
  lastDayOfFestivalIsToday: boolean;

  constructor(private http: HttpClient, private route: ActivatedRoute, private festivalService: FestivalService) {
  }

  ngOnInit(): void {
    this.festivalEnglishName = this.route.snapshot.paramMap.get('festivalEnglishName');

    if (this.festivalEnglishName) {
      this.festivalsSubscription = this.festivalService.festivalsSubject.subscribe(festivals => {
        if (festivals) {
          const festivalEnglishName = this.route.snapshot.paramMap.get('festivalEnglishName');
          this.festivalService.onGetCurrentFestival(festivalEnglishName);

          for (const festival of festivals) {
            if (festival.logo) {
              festival.icon = {
                url: `https://api.hanadiv.org/images/${festival.logo}`,
                scaledSize: {
                  width: 76,
                  height: 48
                }
              };
            }
          }
          this.festival = festivals.find(item => item.festivalEnglishName === this.festivalEnglishName);

          const festivalMonthNumber = moment(this.festival.fromDate).format('M');
          this.formattedFromDate = moment(this.festival.fromDate).format('D');
          this.formattedUntilDate = moment(this.festival.untilDate).format('D') + '.' + festivalMonthNumber;

          const currentDate = moment().format();
          this.isTodayBeforeFestivalDates = moment(currentDate).isBefore(this.festival.fromDate);
          this.isTodayBetweenFestivalDates = moment(currentDate).isBetween(this.festival.fromDate, this.festival.untilDate, undefined, '[]');
          this.lastDayOfFestivalIsToday = moment(currentDate).isSame(this.festival.untilDate, 'day');

          if (this.isTodayBeforeFestivalDates) {
            this.startCounter();
          }
        }
      });
    }
  }

  calculateDateDiff(): void {
    const festivalDateString = new Date(this.festival.fromDate);

    const second = 1000;
    const minute = second * 60;
    const hour = minute * 60;
    const day = hour * 24;

    const now = new Date();
    // @ts-ignore
    const millisecondsDifferences = festivalDateString - now;
    if (millisecondsDifferences >= 0) {
      const days = Math.floor(millisecondsDifferences / day);
      const hours = Math.floor((millisecondsDifferences % day) / hour);
      const minutes = Math.floor((millisecondsDifferences % hour) / minute);
      const seconds = Math.floor((millisecondsDifferences % minute) / second);

      this.timeUntilFestival = {
        days,
        hours,
        minutes,
        seconds
      };
    } else {
      // date expired
    }
  }

  startCounter(): void {
    this.calculateDateDiff();
    this.timeCounter = setInterval(() => {
      this.calculateDateDiff();
    }, 1000);
  }

  ngOnDestroy(): void {
    clearInterval(this.timeCounter);
    this.festivalsSubscription.unsubscribe();
  }

}
