import {Component, OnDestroy, OnInit} from '@angular/core';
import {Festival} from '../shared/models/festival.model';
import {faTimesCircle, faSignOutAlt} from '@fortawesome/free-solid-svg-icons';
import {FestivalService} from '../shared/services/festival.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {User} from '../shared/models/user.model';
import {AuthService} from '../auth/auth.service';

@Component({
  selector: 'app-navbar-menu',
  templateUrl: './navbar-menu.component.html',
  styleUrls: ['./navbar-menu.component.css']
})
export class NavbarMenuComponent implements OnInit, OnDestroy {
  user: User;
  userSubscription: Subscription;
  festival: Festival;
  festivalSubscription: Subscription;
  festivalIsOverSubscription: Subscription;
  isCollapsed = true;
  faTimesCircle = faTimesCircle;
  faSignOutAlt = faSignOutAlt;
  festivalIsOver: boolean;
  hideFestivalMenuOptions: boolean;

  constructor(
    private festivalService: FestivalService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    const festivalEnglishName = this.route.snapshot.paramMap.get('festivalEnglishName');
    this.hideFestivalMenuOptions = !festivalEnglishName; // hide festival options if there is no festival name in current route

    this.userSubscription = this.authService.user.subscribe(user => {
      this.user = user;
    });

    this.festivalSubscription = this.festivalService.currentFestivalSubject.subscribe(festival => {
      if (festival) {
        this.festival = festival;
        this.festivalService.checkIfFestivalIsOver(festival);
        this.festivalIsOverSubscription = this.festivalService.festivalIsOverSubject.subscribe((isOver: boolean) => {
          this.festivalIsOver = isOver;
        });
      }
    });
  }

  logout(): void {
    localStorage.removeItem('userData');
    this.router.navigate([`/`]);
    window.location.reload();
  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
    this.festivalSubscription.unsubscribe();
  }

}
