import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AuthService} from '../auth/auth.service';
import {FacebookLoginProvider, GoogleLoginProvider, SocialAuthService, SocialUser} from '@abacritt/angularx-social-login';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: [
    '../shared/styles/forms-style.css',
    './login.component.css'
  ]
})
export class LoginComponent implements OnInit, OnDestroy {

  loginForm: UntypedFormGroup;
  isLoading: boolean;
  errorMessage: string;
  message: string;


  // google login
  user: SocialUser | undefined;
  loggedIn = false;

  authServiceUserSubscription: Subscription | undefined;
  authServiceLoginSubscription: Subscription | undefined;
  // socialAuthServiceLoginSubscription: Subscription | undefined;

  constructor(
    public router: Router,
    private authService: AuthService,
    // private socialAuthService: SocialAuthService
  ) {
  }

  ngOnInit(): void {
    // redirect user on login
    this.authServiceUserSubscription = this.authService.user.subscribe(user => {
      if (user) {
        this.authService.onLoginSuccess();
      }
    });

    this.loginForm = new UntypedFormGroup({
      email: new UntypedFormControl('', [Validators.required, Validators.email]),
      password: new UntypedFormControl('', [Validators.required])
    });

    /*this.socialAuthServiceLoginSubscription = this.socialAuthService.authState.subscribe((user) => {
      this.loggedIn = (user != null);
      if (user) {
        this.user = user;
        this.authService.onLoginWithSocialProfile(user).subscribe(res => {
          this.isLoading = false;
        }, error => {
          this.isLoading = false;
          this.errorMessage = 'שם משתמש/ת או סיסמא שגויים :(';
        });
      }
    });*/
  }

  onLogin(): void {
    if (this.loginForm.valid) {
      this.isLoading = true;
      this.authServiceLoginSubscription = this.authService.onLogin(this.loginForm.value).subscribe(res => {
        this.isLoading = false;
      }, error => {
        this.isLoading = false;
        if (error) {
          this.errorMessage = error;
        } else {
          this.errorMessage = 'שם משתמש/ת או סיסמא שגויים :(';
        }
      });
    }
  }

  ngOnDestroy(): void {
    if (this.authServiceUserSubscription) {
      this.authServiceUserSubscription.unsubscribe();
    }
    if (this.authServiceLoginSubscription) {
      this.authServiceLoginSubscription.unsubscribe();
    }
    /*if (this.socialAuthServiceLoginSubscription) {
      this.socialAuthServiceLoginSubscription.unsubscribe();
    }*/
  }

}
