export class User {
  constructor(
    public userID: number,
    public name: string,
    public email: string,
    public _token: string,
    public _tokenExpirationDate: Date
  ) {
  }

  get token() {
    if (!this._tokenExpirationDate || new Date() > this._tokenExpirationDate) {
      return null;
    }
    if (this._token) {
      return this._token;
    }
  }

}
