import {Component, OnDestroy, OnInit} from '@angular/core';
import {Festival} from '../shared/models/festival.model';
import {Subscription} from 'rxjs';
import {Event} from '../shared/models/event.model';
import {ActivatedRoute} from '@angular/router';
import {FestivalService} from '../shared/services/festival.service';
import {DataService} from '../shared/services/data.service';
import {faCalendarAlt, faMapPin, faSmile, faLink, faVideo, faEnvelope, faPhone} from '@fortawesome/free-solid-svg-icons';
import * as moment from 'moment';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.css']
})
export class EventComponent implements OnInit, OnDestroy {

  festival: Festival;
  festivalSubscription: Subscription;
  event: Event;
  eventSubscription: Subscription;
  faCalendarAlt = faCalendarAlt;
  faMapPin = faMapPin;
  faSmile = faSmile;
  faLink = faLink;
  faVideo = faVideo;
  faEnvelope = faEnvelope;
  faPhone = faPhone;
  formattedDate;
  dayOfEvent;
  fromHour;
  untilHour;

  constructor(
    private route: ActivatedRoute,
    private festivalService: FestivalService,
    private dataService: DataService
  ) {
  }

  ngOnInit(): void {
    this.festivalSubscription = this.festivalService.festivalsSubject.subscribe(festivals => {
      if (festivals) {
        const festivalEnglishName = this.route.snapshot.paramMap.get('festivalEnglishName');
        this.festival = this.festivalService.onGetCurrentFestival(festivalEnglishName);
        this.getEvent();
      }
    });
  }

  getEvent(): void {
    const eventID = this.route.snapshot.paramMap.get('eventID');
    this.eventSubscription = this.dataService.getEvent(this.festival.festivalEnglishName, eventID).subscribe((event: Event) => {
      if (event) {
        this.event = event[0];
        this.formattedDate = moment(this.event.date).format('DD/MM');
        this.dayOfEvent = moment(this.event.date).locale('he').format('dddd');
        this.fromHour = moment(this.event.hour, 'h:mm:ss A').format('HH:mm');
        this.untilHour = moment(this.event.hour, 'h:mm:ss A').add(this.event.duration, 'minutes').format('HH:mm');

      }
    });
  }

  getLink(event: Event): string {
    if (event.externalLink1) {
      if (event.externalLink1.indexOf('https://') > -1) {
        return event.externalLink1;
      } else if (event.externalLink1.indexOf('http://') > -1) {
        return event.externalLink1;
      } else {
        return `http://${event.externalLink1}`;
      }
    }
  }

  ngOnDestroy(): void {
    this.festivalSubscription.unsubscribe();
    this.eventSubscription.unsubscribe();
  }

}
