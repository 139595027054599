<div class="container-fluid" *ngIf="festival">
  <app-navbar-title-back title="דיווח על תקלה"></app-navbar-title-back>

  <div class="mb-7 mt-5">
    <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
      <label class="form-label">
        <input type="text" class="form-control" formControlName="title" placeholder="כותרת ההודעה">
      </label>
      <label class="form-label">
        <textarea class="form-control" formControlName="description" placeholder="תוכן ההודעה"></textarea>
      </label>
      <div class="text-center mt-2">
        <button class="btn btn-submit font-size-18 pe-3 ps-3 w-50" type="submit">שליחה</button>
      </div>
      <div class="alert alert-success mt-5" *ngIf="message">{{message}}</div>
    </form>
  </div>
  <app-footer-navbar></app-footer-navbar>
</div>
