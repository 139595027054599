<div class="col-2">
  <img id="navbar-menu" src="assets/hamburger.svg" alt="" (click)="isCollapsed = !isCollapsed"
       [attr.aria-expanded]="!isCollapsed" aria-controls="mobile-menu">
</div>

<div id="mobile-menu" class="container" [ngbCollapse]="isCollapsed">
  <div class="d-flex mt-4">
    <div class="col d-flex me-2">
      <fa-icon [icon]="faTimesCircle" size="2x" (click)="isCollapsed = !isCollapsed"></fa-icon>
    </div>
    <div *ngIf="user" class="col d-flex align-items-center justify-content-end ms-2" (click)="logout()">
      <span class="ms-2">יציאה</span>
      <fa-icon [icon]="faSignOutAlt" size="2x"></fa-icon>
    </div>
  </div>
  <div *ngIf="user" class="text-center">
    <span>שלום </span>
    <span>{{user.name}}</span>
  </div>
  <div *ngIf="festival && !hideFestivalMenuOptions">
    <div class="d-flex justify-content-center mt-3 mb-3 bg-white" routerLink="/festivals/list">
      <img id="festival-logo" src="https://api.hanadiv.org/images/{{festival.logo}}" alt="לוגו פסטיבל">
    </div>
    <div class="d-flex justify-content-center mb-4">
      <div class="change-festival pt-1 pb-1 ps-4 pe-4" routerLink="/festivals/list">להחליף פסטיבל</div>
    </div>
    <div *ngIf="!festivalIsOver" class="d-flex me-1 mb-1 align-items-center" routerLink="/festival/{{festival.festivalEnglishName}}/my-area">
      <img src="assets/menu-option-user.svg" alt="">
      <div class="me-1 font-size-26">איזור אישי</div>
    </div>
    <div *ngIf="!festivalIsOver" class="d-flex me-1 mb-1 align-items-center" routerLink="/festival/{{festival.festivalEnglishName}}/initiatives-list">
      <img src="assets/menu-option-initiative.svg" alt="">
      <div class="me-1 font-size-26">יוזמות</div>
    </div>
    <div *ngIf="!festivalIsOver" class="d-flex me-1 mb-1 align-items-center" routerLink="/festival/{{festival.festivalEnglishName}}/places-list">
      <img src="assets/menu-option-place.svg" alt="">
      <div class="me-1 font-size-26">מקומות</div>
    </div>
    <!--<div *ngIf="!festivalIsOver" class="d-flex me-1 mb-1 align-items-center" routerLink="/festival/{{festival.festivalEnglishName}}/events-list">
      <img src="assets/Icons_Events_White.svg" alt="">
      <div class="me-1 font-size-26">תכניה</div>
    </div>-->
    <div *ngIf="!festivalIsOver" class="d-flex me-1 mb-1 align-items-center" routerLink="/festival/{{festival.festivalEnglishName}}/my-messages">
      <img src="assets/menu-option-drawer.svg" alt="הודעות">
      <div class="me-1 font-size-26">הודעות</div>
    </div>
    <div *ngIf="!festivalIsOver" class="d-flex me-1 mb-1 align-items-center" routerLink="/festival/{{festival.festivalEnglishName}}/contact">
      <img src="assets/menu-option-envelope.svg" alt="דיווח על תקלה">
      <div class="me-1 font-size-26">דיווח על תקלה</div>
    </div>
  </div>
</div>
