<div class="d-flex flex-wrap page-navbar text-center pt-1 justify-content-space-evenly">
  <div class="col-2">
    <app-previous-page-icon url="/festival/{{festival.festivalEnglishName}}/my-events"></app-previous-page-icon>
  </div>
  <div class="col-8">{{ festival.name }}</div>
  <app-navbar-menu></app-navbar-menu>
  <div class="row">
    <div class="col-12">
      <span class="font-size-24 font-weight-500">עריכת אירוע</span>
    </div>
  </div>
  <div class="col-12">
    <div class="d-flex font-size-16 text-center">
      <div class="col-4" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
        <a class="textual-link color-on-surface font-size-14 font-weight-500" routerLink="/festival/{{festival.festivalEnglishName}}/edit-event/details/{{event.eventID}}">זמני האירוע</a>
      </div>
      <div class="col-4" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
        <a class="textual-link color-on-surface font-size-14 font-weight-500" routerLink="/festival/{{festival.festivalEnglishName}}/edit-event/participants/{{event.eventID}}/">אנשי קשר</a>
      </div>
      <div class="col-4" *ngIf="user.userID===event.initiativeOwnerUserID" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
        <a class="textual-link color-on-surface font-size-14 font-weight-500" routerLink="/festival/{{festival.festivalEnglishName}}/edit-initiative/{{event.initiativeID}}">פרטי היוזמה</a>
      </div>
      <div class="col-4" *ngIf="user.userID===event.placeOwnerUserID" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
        <a class="textual-link color-on-surface font-size-14 font-weight-500" routerLink="/festival/{{festival.festivalEnglishName}}/edit-place/{{event.placeID}}">פרטי המקום</a>
      </div>
      <!--<div class="col-4" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
        <a class="textual-link color-on-surface font-size-16" routerLink="/festival/{{festival.festivalEnglishName}}/edit-event/participants/{{event.eventID}}">משתתפים/ות</a>
      </div>-->
    </div>
  </div>
</div>
