import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Festival} from '../shared/models/festival.model';
import {Subscription} from 'rxjs';
import {Event} from '../shared/models/event.model';
import {ActivatedRoute, Router} from '@angular/router';
import {DataService} from '../shared/services/data.service';
import {Attendee} from '../shared/models/attendee.model';
import * as moment from 'moment';
import {EventAttendees} from '../shared/models/event-attendees.model';

@Component({
  selector: 'app-event-attendees-list',
  templateUrl: './event-attendees-list.component.html',
  styleUrls: ['./event-attendees-list.component.css']
})
export class EventAttendeesListComponent implements OnInit, OnDestroy {

  @Input() festival: Festival;
  @Input() event: Event;
  festivalSubscription: Subscription;
  eventAttendeesSubscription: Subscription;
  eventAttendees: Attendee[];
  waitingList: Attendee[];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dataService: DataService
  ) {
  }

  ngOnInit(): void {
    this.eventAttendeesSubscription = this.dataService.getEventAttendees(this.festival.festivalEnglishName, this.event.eventID)
      .subscribe((res: EventAttendees) => {
        this.eventAttendees = res.eventAttendees;
        this.waitingList = res.waitingList;
      });
  }

  getDate(date): string {
    return moment(date).format('DD-MM-YYYY');
  }

  getHour(date): string {
    return moment(date).format('HH:mm');
  }

  ngOnDestroy(): void {
    this.eventAttendeesSubscription.unsubscribe();
  }

}
