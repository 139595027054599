import {Component, OnDestroy, OnInit} from '@angular/core';
import {Festival} from '../shared/models/festival.model';
import {ActivatedRoute} from '@angular/router';
import {FestivalService} from '../shared/services/festival.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-festival-options',
  templateUrl: './festival-options.component.html',
  styleUrls: ['./festival-options.component.css']
})
export class FestivalOptionsComponent implements OnInit, OnDestroy {

  festival: Festival;
  festivalsSubscription: Subscription;

  constructor(private route: ActivatedRoute, private festivalService: FestivalService) {
  }

  ngOnInit(): void {
    this.festivalsSubscription = this.festivalService.festivalsSubject.subscribe(festivals => {
      if (festivals) {
        const festivalEnglishName = this.route.snapshot.paramMap.get('festivalEnglishName');
        this.festival = this.festivalService.onGetCurrentFestival(festivalEnglishName);
      }
    });
  }

  ngOnDestroy(): void {
    this.festivalsSubscription.unsubscribe();
  }

}
