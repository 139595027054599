import {Component, OnDestroy, OnInit} from '@angular/core';
import {Festival} from '../shared/models/festival.model';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {FestivalService} from '../shared/services/festival.service';
import {DataService} from '../shared/services/data.service';
import {Event} from '../shared/models/event.model';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-edit-event',
  templateUrl: './edit-event.component.html',
  styleUrls: [
    '../shared/styles/add-initiative-shared.css',
    '../shared/styles/forms-style.css',
    '../shared/styles/list.css',
    '../add-place4/add-place4.component.css',
    './edit-event.component.css'
  ]
})
export class EditEventComponent implements OnInit, OnDestroy {
  festival: Festival;
  festivalSubscription: Subscription;
  eventSubscription: Subscription;
  updateEventSubscription: Subscription;
  event: Event;
  editEventForm: UntypedFormGroup;
  formattedFromDate;
  dateRange = [];
  selectedDate;
  selectedHour;
  hours = this.dataService.hours;
  data = {};

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private festivalService: FestivalService,
    private dataService: DataService,
  ) {
  }

  ngOnInit(): void {
    this.festivalSubscription = this.festivalService.festivalsSubject.subscribe(festivals => {
      if (festivals) {
        const festivalEnglishName = this.route.snapshot.paramMap.get('festivalEnglishName');
        this.festival = this.festivalService.onGetCurrentFestival(festivalEnglishName);
        const eventID = this.route.snapshot.paramMap.get('eventID');
        this.eventSubscription = this.dataService.getEvent(festivalEnglishName, eventID).subscribe((events: Event[]) => {
          this.event = events[0];
          this.buildDates();
          this.buildForms();
          this.setFormData();
        });
      }
    });
  }

  buildDates(): void {
    this.selectedDate = moment(this.festival.fromDate);
    for (const m = moment(this.festival.fromDate); m.diff(this.festival.untilDate, 'days') <= 0; m.add(1, 'days')) {
      const fullDate = m.format('YYYY-MM-DD');
      this.setDataObject(fullDate);
      this.dateRange.push({
        day: m.locale('he').format('dddd'),
        dayMonth: m.format('DD/MM'),
        fullDate
      });
    }
    this.selectDate(this.event.date);
    this.selectHour(this.event.hour);
  }

  setDataObject(dateString): void {
    this.data[dateString] = {hours: []};
  }

  selectDate(date): void {
    this.selectedDate = date;
    const placeAvailabilityDatetime = this.event.placeAvailabilityDatetime.find(item => item.date === date);
    this.hours = placeAvailabilityDatetime.hours;
  }

  selectHour(hour): void {
    this.resetHours();
    this.data[this.selectedDate].hours.push(hour);
  }

  resetHours(): void {
    for (const date of Object.keys(this.data)) {
      this.data[date].hours = [];
    }
  }

  isHourSelected(hour): boolean {
    if (this.data[this.selectedDate]) {
      if (this.data[this.selectedDate].hours.includes(hour)) {
        return true;
      }
    }
    return false;
  }

  isHourUnavailable(hour): boolean {
    if (hour) {
      const notAvailableDate = this.event.placeIsNotAvailableAtDateTime.find(item => item.date === this.selectedDate);
      return !notAvailableDate?.blockedHours.includes(hour) || false;
    }
  }

  buildForms(): void {
    this.editEventForm = new UntypedFormGroup({
      eventID: new UntypedFormControl('', Validators.required),
      date: new UntypedFormControl('', Validators.required),
      hour: new UntypedFormControl('', Validators.required),
    });
  }

  setFormData(): void {
    for (const key of Object.keys(this.event)) {
      if (this.editEventForm.controls[key]) {
        this.editEventForm.controls[key].setValue(this.event[key]);
      }
    }
  }

  onSubmit(): void {
    const data = {
      eventID: this.event.eventID,
      date: this.selectedDate,
      hour: this.data[this.selectedDate].hours[0],
    };
    this.updateEventSubscription = this.dataService.updateEvent(this.festival.festivalEnglishName, data).subscribe(res => {
      this.router.navigate([`/festival/${this.festival.festivalEnglishName}/my-events`]);
    });
  }

  ngOnDestroy(): void {
    this.festivalSubscription.unsubscribe();
    if (this.eventSubscription) {
      this.eventSubscription.unsubscribe();
    }
    if (this.updateEventSubscription) {
      this.updateEventSubscription.unsubscribe();
    }
  }

}
