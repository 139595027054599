import {Component, OnDestroy, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FestivalService} from '../shared/services/festival.service';
import {ActivatedRoute} from '@angular/router';
import {Festival} from '../shared/models/festival.model';
import {DataService} from '../shared/services/data.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-add-initiative6',
  templateUrl: './add-initiative6.component.html',
  styleUrls: [
    '../shared/styles/add-initiative-shared.css',
    '../shared/styles/forms-style.css',
    './add-initiative6.component.css'
  ]
})
export class AddInitiative6Component implements OnInit, OnDestroy {
  localStorageKey = 'addInitiativeFormData';
  festival: Festival;
  festivalsSubscription: Subscription;
  agree: boolean;
  isVirtual: boolean;
  loaded: boolean;
  showFooter: boolean;
  closeResult = '';

  constructor(private modalService: NgbModal,
              private festivalService: FestivalService,
              private dataService: DataService,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.festivalsSubscription = this.festivalService.festivalsSubject.subscribe(festivals => {
      if (festivals) {
        const festivalEnglishName = this.route.snapshot.paramMap.get('festivalEnglishName');
        this.festival = this.festivalService.onGetCurrentFestival(festivalEnglishName);
        this.loadFormWithDataFromLocalStorage();
        this.loaded = true;
      }
    });
  }

  onSubmit(eventCreatedModal, virtualEventCreatedModal): void {
    if (this.agree) {
      this.showFooter = true;
      this.dataService.save(this.localStorageKey, this.festival.festivalEnglishName);
      if (this.isVirtual) {
        this.open(virtualEventCreatedModal);
      } else {
        this.open(eventCreatedModal);
      }
    }
  }

  open(content): void {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
    }, (reason) => {
    });
  }

  loadFormWithDataFromLocalStorage(): void {
    const localStorageData = this.dataService.getLocalStorageData(this.localStorageKey);
    if (localStorageData && localStorageData[1]) {
      this.isVirtual = localStorageData[1].virtual;
    }
  }

  ngOnDestroy(): void {
    this.festivalsSubscription.unsubscribe();
  }
}
