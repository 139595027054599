<div class="container-fluid p-0">
  <app-navbar-title-subtitle-step-back [festival]="festival" title="יצירת יוזמה" subTitle="פרטים בסיסיים" step="1" totalSteps="6"></app-navbar-title-subtitle-step-back>
  <div class="desktop-content-box" *ngIf="festival && dynamicFormData">
    <div class="d-flex h-100 festivals-box">
      <div class="mt-4 col-12 font-size-17 font-weight-400">אז מה אתם רוצים לעשות? פרטי היוזמה שלכם יופיעו בתכניה, ויהיה ניתן לערוך אותם בהמשך.</div>
    </div>
    <div>
      <form [formGroup]="addInitiativeForm" (ngSubmit)="onSubmit()">
        <label class="form-label mt-3">
          <input type="text" class="form-control" placeholder="שם היוזמה" formControlName="name">
        </label>
        <div class="color-red mb-1" *ngIf="addInitiativeFormSubmitted && !addInitiativeForm.controls.name.valid">נא להזין את שם היוזמה</div>
        <label class="form-label mt-3">
          <input type="text" class="form-control" placeholder="תיאור היוזמה" formControlName="description" maxlength="90">
        </label>
        <div class="color-red mb-1" *ngIf="addInitiativeFormSubmitted && !addInitiativeForm.controls.description.valid">נא להזין את תיאור היוזמה</div>
        <label class="form-label mt-3">
          <textarea type="text" class="form-control" placeholder="פירוט היוזמה" formControlName="details"></textarea>
        </label>
        <div class="color-red mb-1" *ngIf="addInitiativeFormSubmitted && !addInitiativeForm.controls.details.valid">נא להזין את פירוט היוזמה</div>
        <label for="duration" class="form-label">
          <span>משך היוזמה בדקות</span>
        </label>
        <div class="duration-box mt-4">
          <div>
            <img src="assets/increase.svg" (click)="addDuration()" alt="הוספת משך יוזמה" class="pointer">
          </div>
          <div>
            <input id="duration" type="number" disabled class="form-control" formControlName="duration">
          </div>
          <div>
            <img src="assets/decrease.svg" (click)="decreaseDuration()" alt="הסרת משך יוזמה" class="pointer">
          </div>
        </div>
        <div class="color-red mt-3" *ngIf="addInitiativeFormSubmitted && addInitiativeForm.controls.duration.value===0">נא להזין את משך היוזמה</div>
        <div class="container mt-2 mb-2" *ngIf="dynamicFormData">
          <div class="mt-4 mb-4">כמה אנשים יכולים להשתתף בפעילות?</div>
          <div class="col-9 number-of-participants">{{numberOfParticipants}}</div>
          <mv-slider [(value)]="numberOfParticipants" [min]="minimumParticipants" [max]="maximumParticipants"></mv-slider>
        </div>
        <div class="color-red mt-4" *ngIf="addInitiativeFormSubmitted && numberOfParticipants===0">נא להזין כמה אנשים יכולים להשתתף ביוזמה</div>
        <div class="mt-4 p-4 radio-group" *ngIf="dynamicFormData">
          <div class="group-title">קהל יעד</div>
          <label *ngFor="let item of dynamicFormData.publics" class="form-label group-item radio-option">
            <input type="radio" class="form-control" value="{{item.value}}" name="public" formControlName="public">
            <span class="pe-2 radio-label">{{item.title}}</span>
          </label>
        </div>
        <div class="color-red mb-1" *ngIf="addInitiativeFormSubmitted && !addInitiativeForm.controls.public.valid">נא לבחור את קהל היעד</div>
        <div class="mt-4 p-4 radio-group" *ngIf="dynamicFormData">
          <div class="group-title">לאן היוזמה מתאימה?</div>
          <label *ngFor="let item of dynamicFormData.areaSettings" class="form-label group-item radio-option">
            <input type="radio" class="form-control" value="{{item.value}}" name="areaSettings" formControlName="areaSettings">
            <span class="pe-2 radio-label">{{item.title}}</span>
          </label>
        </div>
        <div class="color-red mb-1" *ngIf="addInitiativeFormSubmitted && !addInitiativeForm.controls.areaSettings.valid">נא לבחור לאן היוזמה מתאימה</div>

        <!--<div class="checkbox-group form-check">
          <label class="form-label checkbox-option">
            <input type="checkbox" class="form-control form-check-input" formControlName="virtual">
            <span class="checkbox-label form-check-label pe-4">היוזמה שלי וירטואלית ואינה דורשת מקום פיזי</span>
          </label>
        </div>-->

        <div *ngIf="addInitiativeForm.controls.virtual.value">
          Choose date and hour for virtual initiative
          <div class="d-flex mt-4 text-center font-size-17">
            <div class="col pb-3 select-date-box" *ngFor="let item of dateRange" (click)="selectDate(item.fullDate)" [class.selected-date]="item.fullDate===selectedDate">
              <div>{{item.day}}</div>
              <div>{{item.dayMonth}}</div>
            </div>
          </div>
          <div class="d-flex select-hours-box mt-4 justify-content-center">
            <div class="hour me-2 mb-2 p-2" *ngFor="let item of hours" (click)="selectHour(item)" [class.selected-hour]="isHourSelected(item)">
              {{item}}
            </div>
          </div>
          <div *ngIf="numberOfSelectedAvailableHours===0" class="color-red mb-1">נא לסמן מתי האירוע יתקיים</div>
        </div>

        <div class="text-center mt-5">
          <button class="btn btn-submit mb-5 w-50 font-size-16 font-weight-500 pe-3 ps-3" type="submit">לבחירת הז'אנר</button>
        </div>
      </form>
    </div>
  </div>
  <div *ngIf="!festival || !dynamicFormData">
    <app-loading-spinner></app-loading-spinner>
  </div>
</div>
