import {AfterViewChecked, Component, OnDestroy, OnInit} from '@angular/core';
import {Festival} from '../shared/models/festival.model';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {FestivalService} from '../shared/services/festival.service';
import {DataService} from '../shared/services/data.service';
import {Initiative} from '../shared/models/initiative.model';
import {Subscription} from 'rxjs';
import {environment} from '../../environments/environment';
import {FilterInitiativeDynamicData} from '../filter-initiatives-list/filterInitiativeDynamicData.model';
import {HttpClient} from '@angular/common/http';
import {faWindowClose} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-initiatives-list',
  templateUrl: './initiatives-list.component.html',
  styleUrls: [
    '../shared/styles/list.css',
    '../shared/styles/forms-style.css',
    './initiatives-list.component.css'
  ]
})
export class InitiativesListComponent implements OnInit, AfterViewChecked, OnDestroy {

  festival: Festival;
  festivalSubscription: Subscription;
  routeSubscription: Subscription;
  initiatives: Initiative[];
  isScrollExecuted = false;

  search;
  filters;
  filterByAreaSettings;
  filteredAreaSettings;

  filterByGenre;
  filteredGenre;

  filterByNumberOfParticipants;
  filteredNumberOfParticipants;

  filterByPublic;
  filteredPublic;

  faWindowClose = faWindowClose;

  constructor(
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private festivalService: FestivalService,
    private dataService: DataService
  ) {
  }

  ngOnInit(): void {
    this.festivalSubscription = this.festivalService.festivalsSubject.subscribe(festivals => {
      if (festivals) {
        const festivalEnglishName = this.route.snapshot.paramMap.get('festivalEnglishName');
        this.festival = this.festivalService.onGetCurrentFestival(festivalEnglishName);
        this.buildData();
      }
    });

    // listen to route changes - used to apply filters
    this.routeSubscription = this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.buildData();
      }
    });
  }

  ngAfterViewChecked(): void {
    if (this.initiatives && this.initiatives.length > 0 && !this.isScrollExecuted) {
      this.scrollToLastViewedEventInList();
    }
  }

  private scrollToLastViewedEventInList(): void {
    const lastClickedEventID = localStorage.getItem('lastClickedInitiativeID');

    if (lastClickedEventID && !this.isScrollExecuted) {
      setTimeout(() => {
        const element = document.getElementById(lastClickedEventID);
        if (element) {
          element.scrollIntoView({});
          this.isScrollExecuted = true;
          localStorage.removeItem('lastClickedInitiativeID');
        }
      }, 100);
    }
  }


  buildData(): void {
    this.buildFilters();
    this.getInitiatives();
  }

  buildFilters(): void {
    // @ts-ignore
    const filterByAreaSettings = this.route.snapshot.paramMap.params.areaSettings;
    this.filterByAreaSettings = filterByAreaSettings;
    // @ts-ignore
    const filterByGenre = this.route.snapshot.paramMap.params.genre;
    this.filterByGenre = filterByGenre;
    // @ts-ignore
    const filterByNumberOfParticipants = this.route.snapshot.paramMap.params.numberOfParticipants;
    this.filterByNumberOfParticipants = filterByNumberOfParticipants;
    // @ts-ignore
    const filterByPublic = this.route.snapshot.paramMap.params.publicValue;
    this.filterByPublic = filterByPublic;
    this.filters = {filterByAreaSettings, filterByGenre, filterByNumberOfParticipants, filterByPublic};

    if (this.filterByGenre || this.filterByAreaSettings || this.filterByPublic) {
      this.http.get(`${environment.apiURL}/data/filter-initiatives`)
        .subscribe((res: FilterInitiativeDynamicData) => {
          // genre filter
          if (this.filterByGenre) {
            this.filteredGenre = res.genres.find(item => item.genreID === this.filterByGenre);
          }
          // area settings
          if (this.filterByAreaSettings) {
            this.filteredAreaSettings = res.areaSettings.find(item => item.value === this.filterByAreaSettings);
          }
          if (this.filterByPublic) {
            this.filteredPublic = res.publics.find(item => item.value === this.filterByPublic);
          }
        });
    }
    // number of participants
    if (this.filterByNumberOfParticipants) {
      this.filteredNumberOfParticipants = `עד ${this.filterByNumberOfParticipants} משתתפים`;
    }
  }

  getInitiatives(): void {
    this.dataService.getInitiative(this.festival.festivalEnglishName, null, this.filters).subscribe(initiatives => {
      if (initiatives) {
        this.initiatives = initiatives;
      }
    });
  }

  removeFilter(removeFilter): void {
    const filterObject = {
      publicValue: undefined,
      genre: undefined,
      areaSettings: undefined,
      numberOfParticipants: undefined
    };
    if (this.filterByNumberOfParticipants) {
      filterObject.numberOfParticipants = this.filterByNumberOfParticipants;
    }

    if (this.filterByPublic) {
      filterObject.publicValue = this.filterByPublic;
    }
    if (this.filterByGenre) {
      filterObject.genre = this.filterByGenre;
    }
    if (this.filterByAreaSettings) {
      filterObject.areaSettings = this.filterByAreaSettings;
    }
    switch (removeFilter) {
      case 'areaSetting':
        this.filterByAreaSettings = null;
        this.filteredAreaSettings = null;
        delete filterObject.areaSettings;
        break;
      case 'genre':
        this.filterByGenre = null;
        this.filteredGenre = null;
        delete filterObject.genre;
        break;
      case 'numberOfParticipants':
        this.filterByNumberOfParticipants = null;
        this.filteredNumberOfParticipants = null;
        delete filterObject.numberOfParticipants;
        break;
      case 'public':
        this.filterByPublic = null;
        this.filteredPublic = null;
        delete filterObject.publicValue;
        break;
    }
    // remove undefined values from filter object
    Object.keys(filterObject).forEach(key => filterObject[key] === undefined && delete filterObject[key]);

    this.router.navigate([`/festival/${this.festival.festivalEnglishName}/initiatives-list`, filterObject]);
  }

  onNavigateToInitiative(initiativeID): void {
    // Save the clicked eventID or scroll position
    localStorage.setItem('lastClickedInitiativeID', initiativeID);

    // Navigate to the event-page
    this.router.navigate([`/festival/${this.festival.festivalEnglishName}/initiative/`, initiativeID]);
  }

  ngOnDestroy(): void {
    this.festivalSubscription.unsubscribe();
    this.routeSubscription.unsubscribe();
  }
}
