import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {faWindowClose, faFileImage} from '@fortawesome/free-regular-svg-icons';
import {FestivalService} from '../shared/services/festival.service';
import {ActivatedRoute} from '@angular/router';
import {Festival} from '../shared/models/festival.model';
import {DataService} from '../shared/services/data.service';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent implements OnInit, OnDestroy {
  /**
   * Title is the visible text on the view
   */
  @Input() title: string;
  @ViewChild('fileInput')
  fileInput: ElementRef;
  festivalsSubscription: Subscription;
  uploadProgressSubscription: Subscription;
  uploadResponseSubscription: Subscription;
  uploadResponse;
  uploadProgress: number;
  selectedFile = null;
  error: string;
  faWindowClose = faWindowClose;
  faFileImage = faFileImage;
  festival: Festival;

  constructor(
    private festivalService: FestivalService,
    private route: ActivatedRoute,
    private dataService: DataService) {
  }

  ngOnInit(): void {
    this.uploadProgress = 1;
    this.uploadProgressSubscription = this.dataService.uploadProgressSubject.subscribe(percent => {
      this.uploadProgress = percent;
      /*if (percent === 0) {
        this.resetFileInput();
      }*/
    });

    this.festivalsSubscription = this.festivalService.festivalsSubject.subscribe(festivals => {
      if (festivals) {
        const festivalEnglishName = this.route.snapshot.paramMap.get('festivalEnglishName');
        this.festival = this.festivalService.onGetCurrentFestival(festivalEnglishName);
      }
    });

    this.uploadResponseSubscription = this.dataService.uploadResponse.subscribe(uploadResponse => {
      if (uploadResponse) {
        this.uploadResponse = uploadResponse.body;
      }
    });
  }

  resetFileInput(): void {
    if (this.fileInput.nativeElement) {
      this.fileInput.nativeElement.value = '';
    }
  }

  onFileSelected(event): void {
    this.error = null;
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const allowedFormats = ['jpg', 'png', 'jpeg', 'webp'];
      const fileType = selectedFile.name.split('.').pop();
      if (allowedFormats.includes(fileType.toLowerCase())) {
        this.selectedFile = selectedFile;
        this.dataService.onFileUpload(this.festival.festivalEnglishName, selectedFile);
      } else {
        this.error = 'ניתן להעלות קבצים מסוג:  ' + allowedFormats.join(', ');
      }
    }
  }

  ngOnDestroy(): void {
    this.uploadProgressSubscription.unsubscribe();
    this.uploadResponseSubscription.unsubscribe();
    this.festivalsSubscription.unsubscribe();
  }

}
