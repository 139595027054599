import {Pipe, PipeTransform} from '@angular/core';
import {Initiative} from '../shared/models/initiative.model';

@Pipe({
  name: 'filterInitiatives'
})
export class FilterInitiativesPipe implements PipeTransform {

  transform(value: any, filterString: string): Initiative[] {
    if (!value) {
      return;
    }
    if (value.length === 0) {
      return value;
    }
    if (!filterString && value) {
      return value;
    }
    const resultArray = [];
    for (const item of value) {
      if (item.participants) {
        const filteredParticipant = item.participants.findIndex(x => (x.firstName.includes(filterString) || x.lastName.includes(filterString)));
        if (filteredParticipant > -1) {
          if (!this.doesItemInArray(item, resultArray)) {
            resultArray.push(item);
          }
        }
      }
      if (item.title.includes(filterString)) {
        if (!this.doesItemInArray(item, resultArray)) {
          resultArray.push(item);
        }
      }
    }
    return resultArray;
  }

  doesItemInArray(item, array): [] {
    return array.find(x => x.title === item.title);
  }

}
