<div class="mt-3 mb-3 font-weight-bold font-size-20">רשימת משתתפים/ות באירוע</div>

<div class="container admin-box p-3 mb-3" *ngFor="let item of eventAttendees;">
  <div class="d-flex">
    <img class="icon" src="assets/Icons_Profile_Grey.svg" alt="אייקון של מקום">
    <div class="col">
      <div class="d-flex">{{item.firstName}} {{item.lastName}}</div>
      <div class="d-flex mt-2">
        <div class="box p-2 pe-3 ps-3 font-size-14">
          <a href="tel:{{item.phone}}">
            <img class="small-icon" src="assets/Icons_Phone.svg" alt="אייקון של טלפון">
            <span class="me-2">{{item.phone}}</span>
          </a>
        </div>
      </div>
      <div class="d-flex mt-2">
        <div class="box p-2 pe-3 ps-3 font-size-14">
          <a href="mailto:{{item.email}}" target="_blank">
            <img class="small-icon" src="assets/Icons_Mail.svg" alt="אייקון של אימייל">
            <span class="me-2">{{item.email}}</span>
          </a>
        </div>
      </div>
      <div class="d-flex mt-2">
        <span>נרשם/ת בתאריך:</span>
        <span class="me-2">{{getHour(item.insertTimeStamp)}}</span>
        <span class="me-2">{{getDate(item.insertTimeStamp)}}</span>
      </div>
    </div>
  </div>
</div>

<div class="mt-3 mb-3 font-weight-bold font-size-20">רשימת ממתינים/ות</div>

<div class="container admin-box p-3 mb-3" *ngFor="let item of waitingList;">
  <div class="d-flex">
    <img class="icon" src="assets/Icons_Profile_Grey.svg" alt="אייקון של מקום">
    <div class="col">
      <div class="d-flex">{{item.firstName}} {{item.lastName}}</div>
      <div class="d-flex mt-2">
        <div class="box p-2 pe-3 ps-3 font-size-14">
          <a href="tel:{{item.phone}}">
            <img class="small-icon" src="assets/Icons_Phone.svg" alt="אייקון של טלפון">
            <span class="me-2">{{item.phone}}</span>
          </a>
        </div>
      </div>
      <div class="d-flex mt-2">
        <div class="box p-2 pe-3 ps-3 font-size-14">
          <a href="mailto:{{item.email}}" target="_blank">
            <img class="small-icon" src="assets/Icons_Mail.svg" alt="אייקון של אימייל">
            <span class="me-2">{{item.email}}</span>
          </a>
        </div>
      </div>
      <div class="d-flex mt-2">
        <span>נרשם/ת בתאריך:</span>
        <span class="me-2">{{getHour(item.insertTimeStamp)}}</span>
        <span class="me-2">{{getDate(item.insertTimeStamp)}}</span>
      </div>
    </div>
  </div>
</div>
