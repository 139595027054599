import {Component, OnDestroy, OnInit} from '@angular/core';
import {Festival} from '../shared/models/festival.model';
import {FestivalService} from '../shared/services/festival.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-my-area-navbar',
  templateUrl: './my-area-navbar.component.html',
  styleUrls: ['./my-area-navbar.component.css']
})
export class MyAreaNavbarComponent implements OnInit, OnDestroy {

  festival: Festival;
  festivalSubscription: Subscription;

  constructor(private festivalService: FestivalService) {
  }

  ngOnInit(): void {
    this.festivalSubscription = this.festivalService.currentFestivalSubject.subscribe(festival => {
      this.festival = festival;
    });
  }

  ngOnDestroy(): void {
    this.festivalSubscription.unsubscribe();
  }

}
