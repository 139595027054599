<div class="container-fluid p-0">
  <app-navbar-title-subtitle-step-back [festival]="festival" title="יצירת מקום" subTitle="פרטי המקום" step="2" totalSteps="5"></app-navbar-title-subtitle-step-back>
  <div class="desktop-content-box ps-4 pe-4">
    <div class="row h-100 festivals-box">
      <div class="mt-4 col-12 font-size-17">ספרו לנו על המקום שלכם.</div>
      <div class="col-12 font-size-17">תוכלו לערוך את הפרטים בהמשך.</div>
    </div>
    <form [formGroup]="addPlaceForm" (ngSubmit)="onSubmit()">
      <!--<div class="col-12 mt-3">
        <div class="text-center mb-2">כמה אנשים יכולים להיות במקום?</div>
        <div class="text-center">
          <div class="col-9 number-of-participants">{{maxNumberOfPeople}}</div>
          <mv-slider [(value)]="maxNumberOfPeople" [min]="minimumParticipants" [max]="maximumParticipants"></mv-slider>
        </div>
      </div>-->


      <div class="mt-3" *ngIf="dynamicFormData">
        <div class="text-center mb-2">כמה אנשים יכולים להיות במקום?</div>
        <div class="col-9 number-of-participants">{{maxNumberOfPeople}}</div>
        <div>
          <mv-slider [(value)]="maxNumberOfPeople" [min]="minimumParticipants" [max]="maximumParticipants"></mv-slider>
        </div>
      </div>


      <div class="col-12 mt-3">
        <div>
          הארועים הם באחריות בעל\ת המקום ובהתאם לתו הירוק.
          מגבלות התקהלות באירועים פרטיים או בבתים
          עד 100 אנשים במקום פתוח
          עד 50 אנשים במקום סגור
        </div>
      </div>
      <div class="color-red mb-3" *ngIf="formSubmitted && maxNumberOfPeople<1">נא לבחור כמה אנשים יכולים להתארח</div>
      <div class="form-group mt-4 p-4 radio-group" *ngIf="dynamicFormData">
        <div class="group-title">איפה זה קורה?</div>
        <label *ngFor="let item of dynamicFormData.areaSettings" class="group-item radio-option">
          <input type="radio" value="{{item.value}}" name="areaSettings" formControlName="areaSettings">
          <span class="pe-2 radio-label">{{item.title}}</span>
        </label>
      </div>
      <div class="color-red mb-3" *ngIf="formSubmitted && !addPlaceForm.controls.areaSettings.valid">נא לבחור אפשרות</div>
      <div class="form-group mt-4 p-4">

        <div class="d-flex justify-content-between">
          <span>יש חניה</span>
          <label class="form-check-label switch float-left">
            <input type="checkbox" class="form-check-input" formControlName="parking">
            <span class="slider round"></span>
          </label>
        </div>

        <div class="d-flex justify-content-between mt-3">
          <span>נגיש</span>
          <label class="form-check-label switch float-left">
            <input type="checkbox" class="form-check-input" formControlName="accessible">
            <span class="slider round"></span>
          </label>
        </div>

        <div class="d-flex justify-content-between mt-3">
          <span>ידידותי לבעלי חיים</span>
          <label class="form-check-label switch float-left">
            <input type="checkbox" class="form-check-input" formControlName="petFriendly">
            <span class="slider round"></span>
          </label>
        </div>


        <div class="d-flex justify-content-between mt-3">
          <span>אפשר להרעיש</span>
          <label class="form-check-label switch float-left">
            <input type="checkbox" class="form-check-input" formControlName="makeNoise">
            <span class="slider round"></span>
          </label>
        </div>

        <div class="d-flex justify-content-between mt-3">
          <span>יש פסנתר</span>
          <label class="form-check-label switch float-left">
            <input type="checkbox" class="form-check-input" formControlName="piano">
            <span class="slider round"></span>
          </label>
        </div>
      </div>
      <div class="mt-4">
        <label class="form-label">
          <span class="font-size-17">הערות מיוחדות?</span>
          <textarea rows="4" class="mt-2 form-control" formControlName="notice" placeholder="יש חניה בשפע, רישום מיוחד בוויז או דרכי זיהוי מיוחדים, כמו הבית שלנו הוא עם הגדר הירוקה"></textarea>
        </label>
      </div>
      <div class="text-center mt-4">
        <button class="btn btn-submit mb-5 w-50 font-size-16 font-weight-500 pe-3 ps-3" type="submit">לפרטי המקום</button>
      </div>
    </form>
  </div>
</div>
