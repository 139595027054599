import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {BehaviorSubject} from 'rxjs';
import {Message} from '../models/message.model';

@Injectable({
  providedIn: 'root'
})

export class MessageService {

  messages: Message[];
  messagesSubject = new BehaviorSubject<Message[]>(null);

  constructor(private http: HttpClient) {
  }

  onSendMessage(festivalEnglishName, req): void {
    const fd = new FormData();
    fd.append('fromUserID', req.fromUserID);
    fd.append('toUserID', req.toUserID);
    fd.append('regardingPlaceID', req.regardingPlaceID);
    fd.append('regardingInitiativeID', req.regardingInitiativeID);
    fd.append('requestedDate', req.requestedDate);
    fd.append('requestedHour', req.requestedHour);
    this.http.post(`${environment.apiURL}/message?festival=${festivalEnglishName}`, fd).subscribe((messages: Message[]) => {
      // this.setMessages(messages);
      this.getMyMessages(festivalEnglishName);
    });
  }

  getMyMessages(festivalEnglishName): void {
    this.http.get(`${environment.apiURL}/message?festival=${festivalEnglishName}`).subscribe((messages: Message[]) => {
      if (messages) {
        this.setMessages(messages);
      }
    });
  }

  update(festivalEnglishName, data): void {
    this.http.patch(`${environment.apiURL}/message?festival=${festivalEnglishName}`, JSON.stringify(data)).subscribe((messages: Message[]) => {
      this.setMessages(messages);
    });
  }

  private setMessages(messages): void {
    this.messages = messages;
    this.messagesSubject.next(this.messages);
  }

  /*delete(festivalEnglishName, messageID): Observable<any> {
    return this.http.delete<void>(`${environment.apiURL}/message/?festival=${festivalEnglishName}${messageID}`, {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    });
  }*/

}
