import {Component, OnDestroy, OnInit} from '@angular/core';
import {Festival} from '../shared/models/festival.model';
import {HttpClient} from '@angular/common/http';
import {FestivalService} from '../shared/services/festival.service';
import {DataService} from '../shared/services/data.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-add-place3',
  templateUrl: './add-place3.component.html',
  styleUrls: [
    '../shared/styles/add-initiative-shared.css',
    './add-place3.component.css'
  ]
})
export class AddPlace3Component implements OnInit, OnDestroy {
  localStorageKey = 'addPlaceFormData';
  currentStep = 3;
  festival: Festival;
  festivalSubscription: Subscription;
  eligibleForNextStep: boolean;
  imageSource;
  imageUploadSubscription: Subscription;

  constructor(
    private http: HttpClient,
    private festivalService: FestivalService,
    private dataService: DataService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.festivalSubscription = this.festivalService.festivalsSubject.subscribe(festivals => {
      if (festivals) {
        const festivalEnglishName = this.route.snapshot.paramMap.get('festivalEnglishName');
        this.festival = this.festivalService.onGetCurrentFestival(festivalEnglishName);
      }
    });

    // listen to uploaded file changes
    this.eligibleForNextStep = false;
    this.imageUploadSubscription = this.dataService.uploadResponse.subscribe(res => {
      if (res) {
        const uploadedFileData = res.body;
        this.dataService.addFormData(this.localStorageKey, this.currentStep, {
          fileHash: uploadedFileData.fileHash,
          path: uploadedFileData.path
        });
        this.setImageSource(uploadedFileData);
      }
    });

    /*const localStorageData = this.dataService.getLocalStorageData(this.localStorageKey);
    if (localStorageData) {
      const uploadedFile = localStorageData[4];
      if (uploadedFile) {
        this.setImageSource(uploadedFile);
      }
    }*/
    this.loadFormWithDataFromLocalStorage();
  }

  loadFormWithDataFromLocalStorage(): void {
    const localStorageData = this.dataService.getLocalStorageData(this.localStorageKey);
    if (localStorageData) {
      const data = localStorageData[this.currentStep];
      if (data) {
        if (data.fileHash) {
          this.setImageSource(data);
        }
      }
    }
  }

  setImageSource(uploadedFile): void {
    this.imageSource = `${uploadedFile.path}`;
    this.eligibleForNextStep = true;
  }

  onSubmit(): void {
    if (this.eligibleForNextStep) {
      this.router.navigate([`/festival/${this.festival.festivalEnglishName}/add-place/4`]);
    }
  }

  ngOnDestroy(): void {
    this.festivalSubscription.unsubscribe();
    this.imageUploadSubscription.unsubscribe();
  }
}
