<div class="container-fluid mb-10 p-0" *ngIf="place">
  <app-navbar-title-back title="עריכת מקום"></app-navbar-title-back>
  <div class="ps-4 pe-4">
    <form [formGroup]="editPlaceForm" (ngSubmit)="onSubmit()">
      <!-- step 1 -->
      <div>
        <label class="mt-3 form-label">
          <span>תיאור המקום</span>
          <textarea type="text" class="form-control" formControlName="description"></textarea>
        </label>
        <div class="color-red mb-3" *ngIf="formSubmitted && !editPlaceForm.controls.description.valid">נא להזין את תיאור המקום</div>
        <label class="form-label">
          <span>עיר</span>
          <select class="form-control" formControlName="cityID" (change)="onSelectCity()">
            <option value="0">יישוב</option>
            <option *ngFor="let city of cities" [ngValue]="city.cityID">
              {{ city.title }}
            </option>
          </select>
        </label>
        <div class="color-red mb-3" *ngIf="formSubmitted && editPlaceForm.controls.cityID.value==0">נא לבחור יישוב</div>

        <div *ngIf="editPlaceForm.controls.cityID.value">
          <form [formGroup]="neighborhoodSearchForm">
            <label class="mt-3 form-label">
              <input type="text" class="form-control" placeholder="חיפוש שכונה" formControlName="search" (keyup)="resetNeighborhoodSelect()">
            </label>
          </form>
          <div *ngIf="!selectedNeighborhood && neighborhoodSearchForm.controls.search.value.length>2">
            <div *ngFor="let item of neighborhoods | filterNeighborhoods:neighborhoodSearchForm.value.search" (click)="onSelectNeighborhood(item)">
              <div>{{item.title}}</div>
            </div>
          </div>
        </div>

        <label class="mt-3 form-label">
          <span>שם הרחוב</span>
          <input type="text" class="form-control" formControlName="street">
        </label>
        <div class="color-red mb-3" *ngIf="formSubmitted && !editPlaceForm.controls.street.valid">נא להזין את שם הרחוב</div>
        <label class="mt-3 form-label">
          <span>מספר בית</span>
          <input type="number" class="form-control" formControlName="houseNumber">
        </label>
        <div class="color-red mb-3" *ngIf="formSubmitted && !editPlaceForm.controls.houseNumber.valid">נא להזין את מספר הבית</div>
        <label class="mt-3 form-label">
          <span>מספר כניסה</span>
          <input type="text" class="form-control" formControlName="entrance">
        </label>
      </div>

      <!-- step 2-->
      <div>
        <!--<div class="col-12 mt-3 pe-0">
          <div class="text-center mb-2">כמה אנשים יכולים להיות במקום?</div>
          <div class="text-center">
            <div class="col-9 number-of-participants">{{place.maxNumberOfPeople}}</div>
            <mv-slider [(value)]="place.maxNumberOfPeople" [min]="minimumParticipants" [max]="maximumParticipants"></mv-slider>
          </div>
        </div>-->

        <div class="container mt-2 mb-2" *ngIf="dynamicFormData">
          <div class="mt-4 mb-4">כמה אנשים יכולים להיות במקום?</div>
          <div class="col-9 number-of-participants">{{place.maxNumberOfPeople}}</div>
          <mv-slider [(value)]="place.maxNumberOfPeople" [min]="minimumParticipants" [max]="maximumParticipants"></mv-slider>
        </div>

        <div class="form-group mt-4" *ngIf="dynamicFormData">
          <div class="group-title mt-3 me-3">איפה זה קורה?</div>
          <label *ngFor="let item of dynamicFormData.areaSettings" class="group-item mt-2 me-3 pb-2">
            <input type="radio" class="form-check-input" value="{{item.value}}" name="areaSettingValue" formControlName="areaSettingValue">
            <span class="pe-2">{{item.title}}</span>
          </label>
          <div class="color-red mb-3" *ngIf="formSubmitted && !editPlaceForm.controls.areaSettingValue.valid">נא לבחור אפשרות</div>
        </div>
        <div class="form-group mt-4 p-4">
          <div class="d-flex justify-content-between">
            <span>יש חניה</span>
            <label class="form-check-label switch float-left">
              <input type="checkbox" class="form-check-input" formControlName="hasParking">
              <span class="slider round"></span>
            </label>
          </div>
          <div class="d-flex justify-content-between mt-3">
            <span>נגיש</span>
            <label class="form-check-label switch float-left">
              <input type="checkbox" class="form-check-input" formControlName="isAccessible">
              <span class="slider round"></span>
            </label>
          </div>
          <div class="d-flex justify-content-between mt-3">
            <span>ידידותי לבעלי חיים</span>
            <label class="form-check-label switch float-left">
              <input type="checkbox" class="form-check-input" formControlName="petFriendly">
              <span class="slider round"></span>
            </label>
          </div>
          <div class="d-flex justify-content-between mt-3">
            <span>אפשר להרעיש</span>
            <label class="form-check-label switch float-left">
              <input type="checkbox" class="form-check-input" formControlName="canHostNoisyEvents">
              <span class="slider round"></span>
            </label>
          </div>
          <div class="d-flex justify-content-between mt-3">
            <span>יש פסנתר</span>
            <label class="form-check-label switch float-left">
              <input type="checkbox" class="form-check-input" formControlName="piano">
              <span class="slider round"></span>
            </label>
          </div>
        </div>
        <div class="mt-4">
          <label class="form-label">
            <span class="font-size-17">הערות מיוחדות?</span>
            <textarea class="mt-2 form-control" formControlName="notes" placeholder="יש חניה בשפע, רישום מיוחד בוויז או דרכי זיהוי מיוחדים, כמו הבית שלנו הוא עם הגדר הירוקה"></textarea>
          </label>
        </div>
      </div>

      <!-- step 3 -->
      <div>
        <app-file-upload [title]="'הוסיפו תמונה של מקום האירוח'"></app-file-upload>
        <div class="col-10 m-auto" *ngIf="imageSource">
          <img [src]="imageSource" class="rounded img-thumbnail" alt="תמונת האירוע">
        </div>
      </div>

      <!-- step 4 -->
      <div>
        <div class="row mt-4 text-center font-size-17">
          <div class="col pb-3 select-date-box" *ngFor="let item of dateRange" (click)="selectDate(item.fullDate)" [class.selected-date]="item.fullDate===selectedDate">
            <div>{{item.day}}</div>
            <div>{{item.dayMonth}}</div>
          </div>
        </div>
        <div class="row select-hours-box mt-4 justify-content-center">
          <div class="hour me-2 mt-4 p-2"
               *ngFor="let item of hours"
               (click)="selectHour(item)"
               [class.selected-hour]="isHourSelected(item)"
               [class.hour-disabled]="isHourUnavailable(item)">
            <img *ngIf="isEventStartingHour(item)" class="event-flag" src="/assets/event-flag.svg" alt="event flag">
            {{item}}
          </div>
        </div>


        <div class="mt-4">
          <img src="/assets/event-flag.svg" alt="event flag">
          תחילת אירוע קיים
        </div>

        <div *ngIf="formSubmitted && numberOfSelectedAvailableHours===0" class="color-red mb-1">נא לסמן מתי המקום פנוי לארח</div>
        <div *ngIf="editPlaceForm.controls.numberOfInitiativesWillingToHost.value>numberOfSelectedAvailableHours && numberOfSelectedAvailableHours>0" class="color-red mb-1">
          <div>נא לציין מספר חלונות זמן (שעות) כמספר היוזמות שאתה מוכן לארח</div>
          <div>
            <span>בחרת </span>
            <span>{{numberOfSelectedAvailableHours}}</span>
            <span *ngIf="numberOfSelectedAvailableHours===1"> שעה </span>
            <span *ngIf="numberOfSelectedAvailableHours>1"> שעות </span>
            <span>לאירוח ו-</span>
            <span>{{editPlaceForm.controls.numberOfInitiativesWillingToHost.value}}</span>
            <span *ngIf="editPlaceForm.controls.numberOfInitiativesWillingToHost.value===1"> יוזמה </span>
            <span *ngIf="editPlaceForm.controls.numberOfInitiativesWillingToHost.value>1"> יוזמות </span>
            <span>לאירוח</span>
          </div>
        </div>


        <label for="select-number-of-initiatives" class="mt-4 font-size-17">כמה יוזמות אתם מוכנים לארח בפסטיבל?</label>
        <select id="select-number-of-initiatives" class="form-control mt-2" formControlName="numberOfInitiativesWillingToHost">
          <option *ngFor="let item of [1,2,3,4,5,6,7,8,9,10]">
            {{ item }}
          </option>
        </select>
      </div>

      <div *ngIf="formSubmitted" class="mt-3">
        <div class="color-red mb-3" *ngIf="!editPlaceForm.controls.description.valid || !editPlaceForm.controls.street.valid || !editPlaceForm.controls.houseNumber.valid || editPlaceForm.controls.cityID.value==0">
          נא למלא את כל שדות החובה בטופס
        </div>
      </div>

      <div class="d-flex justify-content-center mt-5 mb-5">
        <button class="btn btn-submit w-50 font-size-18 pe-3 ps-3 w-75" type="submit">שמירה</button>
      </div>
      <div class="d-flex justify-content-center mt-5 mb-10">
        <button class="btn btn-delete font-size-18 pe-3 ps-3 w-75" type="button" (click)="delete()">
          <fa-icon [icon]="faTrash" class="float-right"></fa-icon>
          <span>מחיקה</span>
        </button>
      </div>
    </form>
  </div>
  <app-footer-navbar></app-footer-navbar>
</div>
