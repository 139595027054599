<div class="d-flex flex-wrap page-navbar text-center">
  <div class="col-2">
    <app-previous-page-icon></app-previous-page-icon>
  </div>
  <div class="col-8">
    <div class="col-12 mt-3 font-size-16 font-weight-500">{{title}}</div>
    <div class="col-12 mt-1 font-size-24 font-weight-500">{{subTitle}}</div>
  </div>
  <app-navbar-menu></app-navbar-menu>
  <div class="col-12 mt-1">
    <div class="progress justify-content-end">
      <div class="progress-bar" role="progressbar" [style.width]="progressBarWidth" aria-valuemin="0" aria-valuemax="100"></div>
    </div>
  </div>
  <div class="col-12 mt-1 mb-4 font-size-14">
    <span>שלב </span>
    <span>{{step}}</span>
    <span> מתוך </span>
    <span>{{totalSteps}}</span>
  </div>
</div>
