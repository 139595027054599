import {Component, OnInit} from '@angular/core';
import {FestivalService} from './shared/services/festival.service';
import {AuthService} from './auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  title = 'אפלקיציית הנדיב';

  constructor(
    private authService: AuthService,
    private festivalService: FestivalService
  ) {
  }

  ngOnInit(): void {
    this.authService.autoLogin();
    this.festivalService.onGetFestivals();
  }
}
