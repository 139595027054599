import {Component, OnDestroy, OnInit} from '@angular/core';
import {Festival} from '../shared/models/festival.model';
import {ActivatedRoute} from '@angular/router';
import {FestivalService} from '../shared/services/festival.service';
import {Subscription} from 'rxjs';
import {Initiative} from '../shared/models/initiative.model';
import {DataService} from '../shared/services/data.service';

@Component({
  selector: 'app-my-initiatives',
  templateUrl: './my-initiatives.component.html',
  styleUrls: ['./my-initiatives.component.css']
})
export class MyInitiativesComponent implements OnInit, OnDestroy {

  dataLoaded: boolean;
  festival: Festival;
  festivalSubscription: Subscription;
  initiatives: Initiative[];
  userInitiativesSubscription: Subscription;

  constructor(private route: ActivatedRoute, private festivalService: FestivalService, private dataService: DataService) {
  }

  ngOnInit(): void {
    this.festivalSubscription = this.festivalService.festivalsSubject.subscribe(festivals => {
      if (festivals) {
        const festivalEnglishName = this.route.snapshot.paramMap.get('festivalEnglishName');
        this.festival = this.festivalService.onGetCurrentFestival(festivalEnglishName);
        this.dataService.getMyInitiativesSubscription(festivalEnglishName);
      }
    });

    this.userInitiativesSubscription = this.dataService.userInitiativesSubject.subscribe(initiatives => {
      if (initiatives) {
        this.initiatives = initiatives;
        setTimeout(() => {
          this.dataLoaded = true;
        }, 600);
      }
    });
  }

  ngOnDestroy(): void {
    this.festivalSubscription.unsubscribe();
    this.userInitiativesSubscription.unsubscribe();
  }
}
