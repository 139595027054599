import {Component, OnDestroy, OnInit} from '@angular/core';
import {Festival} from '../shared/models/festival.model';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {FestivalService} from '../shared/services/festival.service';

@Component({
  selector: 'app-my-area',
  templateUrl: './my-area.component.html',
  styleUrls: [
    '../shared/styles/list.css',
    './my-area.component.css'
  ]
})
export class MyAreaComponent implements OnInit, OnDestroy {

  festival: Festival;
  festivalSubscription: Subscription;

  constructor(private route: ActivatedRoute, private festivalService: FestivalService) {
  }

  ngOnInit(): void {
    this.festivalSubscription = this.festivalService.festivalsSubject.subscribe(festivals => {
      if (festivals) {
        const festivalEnglishName = this.route.snapshot.paramMap.get('festivalEnglishName');
        this.festival = this.festivalService.onGetCurrentFestival(festivalEnglishName);
      }
    });
  }

  ngOnDestroy(): void {
    this.festivalSubscription.unsubscribe();
  }

}
