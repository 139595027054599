import {Component, OnDestroy, OnInit} from '@angular/core';
import {Festival} from '../shared/models/festival.model';
import {Place} from '../shared/models/place.model';
import {ActivatedRoute} from '@angular/router';
import {FestivalService} from '../shared/services/festival.service';
import {DataService} from '../shared/services/data.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {faTimesCircle, faEnvelope, faPhone} from '@fortawesome/free-solid-svg-icons';
import {Initiative} from '../shared/models/initiative.model';
import {AuthService} from '../auth/auth.service';
import {Subscription} from 'rxjs';
import {User} from '../shared/models/user.model';
import {MessageService} from '../shared/services/message.service';
import {FormatDataService} from '../shared/services/format-data.service';

@Component({
  selector: 'app-place',
  templateUrl: './place.component.html',
  styleUrls: [
    '../shared/styles/list.css',
    './place.component.css'
  ]
})
export class PlaceComponent implements OnInit, OnDestroy {
  user: User;
  userSubscription: Subscription;
  userInitiativesSubscription: Subscription;
  userInitiatives: Initiative[] = [];
  userAvailableInitiatives: Initiative[] = [];
  festivalSubscription: Subscription;
  festival: Festival;
  place: Place;
  selectedUserInitiative: Initiative;
  userSelectedEventTime: { date: string, hour: number };
  eligible: boolean;
  submitted: boolean;
  faTimesCircle = faTimesCircle;
  faEnvelope = faEnvelope;
  faPhone = faPhone;
  errorMessage: string;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private festivalService: FestivalService,
    private dataService: DataService,
    private formatDataService: FormatDataService,
    private messagesService: MessageService,
    private modalService: NgbModal
  ) {
  }

  ngOnInit(): void {
    this.userSubscription = this.authService.user.subscribe(user => {
      this.user = user;
    });

    this.festivalSubscription = this.festivalService.festivalsSubject.subscribe(festivals => {
      if (festivals) {
        const festivalEnglishName = this.route.snapshot.paramMap.get('festivalEnglishName');
        this.festival = this.festivalService.onGetCurrentFestival(festivalEnglishName);
        this.getPlace();
        this.dataService.getMyInitiativesSubscription(this.festival.festivalEnglishName);
      }
    });

    this.userInitiativesSubscription = this.dataService.userInitiativesSubject.subscribe(userInitiatives => {
      this.userInitiatives = userInitiatives;
      this.userAvailableInitiatives = this.userInitiatives.filter(item => item.existingEventWithInitiativeStatus === 0);
    });
  }

  getPlace(): void {
    const placeID = this.route.snapshot.paramMap.get('placeID');
    this.dataService.getPlace(this.festival.festivalEnglishName, placeID).subscribe(place => {
      if (place) {
        this.place = place[0];
      }
    });
  }

  formatAsDay(date: string): string {
    return this.formatDataService.formatAsDay(date);
  }

  selectHour(date, hour): void {
    this.submitted = false;
    this.eligible = false;
    this.userSelectedEventTime = {date, hour};
  }

  isEligible(): boolean {
    return !!this.userSelectedEventTime;
  }

  isHourSelected(date, hour): boolean {
    if (this.userSelectedEventTime) {
      if (this.userSelectedEventTime.date === date && this.userSelectedEventTime.hour === hour) {
        return true;
      }
    }
    return false;
  }

  isHourUnavailable(date, hour): boolean {
    if (date && hour) {
      const notAvailableDate = this.place.placeIsNotAvailableAtDateTime.find(item => item.date === date);
      if (notAvailableDate) {
        return notAvailableDate?.fromHours.includes(hour) || notAvailableDate?.blockedHours.includes(hour) || false;
      }
      return false;
    }
  }

  selectUserInitiative(initiative): void {
    this.selectedUserInitiative = initiative;
  }

  isUserInitiativeSelected(initiative): boolean {
    if (!this.selectedUserInitiative) {
      return false;
    }
    return this.selectedUserInitiative.initiativeID === initiative.initiativeID;
  }

  onSubmit(selectInitiativeModalContent, eventCreatedModal): void {
    this.submitted = true;
    if (this.isEligible()) {
      this.eligible = true;
      if (this.userAvailableInitiatives.length > 1) {
        this.open(selectInitiativeModalContent);
      } else if (this.userAvailableInitiatives.length === 1) {
        this.selectedUserInitiative = this.userAvailableInitiatives[0];

        if (this.place.isOwner) {
          this.onCreateEvent(eventCreatedModal);
        } else {
          this.proceedToSendMessage(eventCreatedModal);
        }
      } else {
        this.errorMessage = 'יש להוסיף יוזמה תרבותית טרם שליחת בקשה להתארח במקום';
        return;
      }
    } else {
      this.eligible = false;
    }
  }

  onCreateEvent(eventCreatedModal): void {
    this.eligible = true;
    const data = {
      placeID: this.place.placeID,
      initiativeID: this.selectedUserInitiative.initiativeID,
      date: this.userSelectedEventTime.date,
      hour: this.userSelectedEventTime.hour
    };

    this.dataService.createEvent(this.festival.festivalEnglishName, data).subscribe(res => {
      this.proceedToSendMessage(eventCreatedModal);
    });
  }

  open(content): void {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
    }, (reason) => {
    });
  }

  proceedToSendMessage(messageModalContent): void {
    if (this.selectedUserInitiative) {
      // show message content
      this.open(messageModalContent);
    }
  }

  sendMessage(messageSentModalContent): void {
    if (this.selectedUserInitiative) {
      const req = {
        fromUserID: this.user.userID,
        toUserID: this.place.hostID,
        regardingPlaceID: this.place.placeID,
        regardingInitiativeID: this.selectedUserInitiative.initiativeID,
        requestedDate: this.userSelectedEventTime.date,
        requestedHour: this.userSelectedEventTime.hour,
      };
      this.messagesService.onSendMessage(this.festival.festivalEnglishName, req);
      this.open(messageSentModalContent);
    }
  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
    this.festivalSubscription.unsubscribe();
    this.userInitiativesSubscription.unsubscribe();
  }
}
