<div class="container-fluid mb-7 ps-0 pe-0" *ngIf="initiative">
  <app-navbar-title-back title="יוזמה"></app-navbar-title-back>

  <div class="desktop-content-box ps-4 pe-4">
    <div class="d-flex">
      <div class="image-container text-center" [style.backgroundImage]="'url('+ initiative.path +')'"></div>
    </div>

    <div class="ps-3 pe-3 font-size-21 font-weight-500">{{initiative.title}}</div>

    <div class="ps-3 pe-3 text-right">
      <div class="image-text-box">
        <div class="font-size-17 font-weight-400" *ngIf="initiative.participants">
          <span>מאת </span>
          <span *ngFor="let participant of initiative.participants; let i=index">
              <span></span>
              <span *ngIf="i>0"> ו</span>
            <span>{{participant.firstName}} {{participant.lastName}}</span>
            </span>
        </div>
      </div>
    </div>

    <!-- description -->
    <div class="mt-4 mb-3">
      <div class="col-12 title font-size-17">תיאור היוזמה</div>
      <div class="col-12">{{initiative.description}}</div>
    </div>

    <!-- details -->
    <div class="mb-3">
      <div class="col-12 title font-size-17">פרטים נוספים</div>
      <div class="col-12">{{initiative.details}}</div>
    </div>

    <!--genre-->
    <div class="mb-3">
      <div class="col-12 title font-size-17">קטגוריה</div>
      <div class="col-12">{{initiative.genre}}</div>
    </div>

    <!-- area -->
    <div class="mb-3">
      <div class="col-12 title font-size-17">לאן היוזמה מתאימה?</div>
      <div class="col-12">{{initiative.area}}</div>
    </div>

    <!-- public -->
    <div class="mb-3">
      <div class="col-12 title font-size-17">קהל היעד</div>
      <div class="col-12">{{initiative.public}}</div>
    </div>

    <!-- limitNumberOfPeople -->
    <div class="mb-3">
      <div class="col-12 title font-size-17">כמה אנשים יכולים להשתתף בפעילות</div>
      <div class="col-12">{{initiative.limitNumberOfPeople}}</div>
    </div>

    <div class="mb-3" *ngIf="initiative.externalLink1 || initiative.externalLink2">
      <div class="col-12 title font-size-17">קישורים חיצוניים</div>
      <a class="btn-primary-link" *ngIf="initiative.externalLink1" href="{{initiative.externalLink1}}" target="_blank">{{initiative.externalLinkLabel1}}</a>
      <a class="btn-primary-link" *ngIf="initiative.externalLink2" href="{{initiative.externalLink2}}" target="_blank">{{initiative.externalLinkLabel2}}</a>
    </div>

    <!-- participants -->
    <div class="mb-3" *ngIf="initiative.participants">
      <div class="col-12 title font-size-17">משתתפים</div>
      <div class="col-12" *ngFor="let participant of initiative.participants; let i=index">
        <span>{{participant.firstName}} {{participant.lastName}}</span>
        <span> {{participant.role}}</span>
      </div>
    </div>

    <!-- organizer name and phone-->
    <div class="mb-3">
      <div class="col-12 font-weight-bold">{{initiative.firstName}} {{initiative.lastName}}</div>
      <div class="btn-primary-link">
        <div>
          <img class="phone-icon ms-1" src="assets/Icons_Phone.svg" alt="תמונה של טלפון">
          <span>{{initiative.phone}}</span>
        </div>
      </div>
    </div>

    <!-- tags -->
    <div class="d-flex col-12 mb-4 justify-content-start" *ngIf="initiative.tags">
      <div class="tag col-3 mb-2 me-2 text-center font-size-12" *ngFor="let tag of initiative.tags; let i=index">
        <span>{{tag.title}}</span>
      </div>
    </div>

    <div class="text-center mb-5" *ngIf="initiative.existingEventWithInitiativeStatus===0">
      <div *ngIf="userPlaces.length===0" class="font-weight-bold color-red font-size-17 mb-3 text-center">על מנת לארח יוזמה זו, יש להוסיף ראשית מקום לאירוח</div>
      <button *ngIf="!initiative.isOwner" class="btn btn-submit mt-5 mb-5 font-size-19 font-weight-bold" type="button" [disabled]="userAvailablePlaces.length === 0"
              (click)="onSubmit(selectPlaceModalContent, selectInvitationDateTime)">
        אני מעוניין לארח
      </button>

      <div *ngIf="userAvailablePlaces.length === 0" class="color-red">
        <div>אין לך שעות פנויות לאירוח,</div>
        <div>הוסף שעות נוספות למקום האירוח שלך</div>
      </div>

      <button *ngIf="initiative.isOwner && userAvailablePlaces.length>0" class="btn btn-submit mt-5 mb-5 font-size-19 font-weight-bold" type="button" (click)="onSubmit(selectPlaceModalContent, selectInvitationDateTime)">
        צור אירוע עם היוזמה שלך
      </button>
    </div>
    <div class="text-center mt-5 mb-5 bg-text-light-blue color-white p-2" *ngIf="initiative.existingEventWithInitiativeStatus>0">
      היוזמה כבר משוייכת לאירוע
    </div>
  </div>
  <app-footer-navbar></app-footer-navbar>
</div>

<ng-template #selectPlaceModalContent let-modal>
  <div class="row modal-header border-bottom-0 justify-content-center mt-4">
    <div class="col-12 font-size-14 font-weight-bold d-flex justify-content-space-between">
      <div>
        <span>הודעה ל</span>
        <span>{{initiative.firstName}} {{initiative.lastName}}</span>
      </div>
      <div>
        <fa-icon [icon]="faTimesCircle" (click)="modal.close()"></fa-icon>
      </div>
    </div>
    <div class="col-12 font-size-12">{{initiative.title}}</div>
  </div>
  <div class="separator"></div>
  <div class="modal-body">
    <div *ngIf="userAvailablePlaces.length > 0">
      <div class="d-flex d-flex justify-content-center">
        <ng-container *ngFor="let place of userPlaces">
          <div class="user-place-item p-2 m-2"
               *ngIf="place.availableForMoreEvents"
               [ngClass]="{'selected-place': isUserPlaceSelected(place)}"
               (click)="selectUserPlace(place)">
            {{place.description}}
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="modal-footer mt-4 mb-3 border-top-0 justify-content-center">
    <button class="col-11 btn btn-submit font-size-22 mb-3" type="button" [disabled]="!selectedUserPlace" (click)="proceedToChooseDateTime(selectInvitationDateTime);modal.close()">שליחת הודעה</button>
  </div>
</ng-template>

<ng-template #selectInvitationDateTime let-modal>
  <div class="row modal-header border-bottom-0 justify-content-center mt-4">
    <div class="col-12 font-size-14 font-weight-bold d-flex justify-content-space-between">
      <div *ngIf="!initiative.isOwner">
        <span>הודעה ל</span>
        <span>{{initiative.firstName}} {{initiative.lastName}}</span>
      </div>
      <div>
        <fa-icon [icon]="faTimesCircle" (click)="modal.close()"></fa-icon>
      </div>
    </div>
    <div *ngIf="!initiative.isOwner" class="col-12 font-size-12">{{initiative.title}}</div>
  </div>
  <div class="separator"></div>
  <div class="modal-body">
    <div>אנא ביחרו את היום והשעה בהם תירצו לארח את היוזמה</div>
    <div *ngFor="let item of selectedUserPlace.availableDatetime" class="mt-3 mb-3">
      <div class="col-12 font-size-17 mb-2 pe-0">
        <span>ביום </span>
        <span class="font-weight-bold">{{formatAsDay(item.date)}}</span>
        <span> בשעות</span>
      </div>
      <div class="hours-box d-flex flex-d-flex-reverse col-12">
        <div class="hour me-2 mt-2 p-2" *ngFor="let hour of item.hours" (click)="selectHour(item.date, hour)"
             [class.selected-hour]="isHourSelected(item.date, hour)"
             [class.hour-disabled]="isHourUnavailable(item.date, hour)">
          {{hour}}
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer mt-4 mb-3 border-top-0 justify-content-center">
    <button *ngIf="!initiative.isOwner" class="col-11 btn btn-submit font-size-22 mb-3" type="button" [disabled]="!userSelectedEventTime" (click)="proceedToSendMessage(messageModalContent);modal.close()">שליחת הודעה</button>
    <button *ngIf="initiative.isOwner" class="col-11 btn btn-submit font-size-22 mb-3" type="button" [disabled]="!userSelectedEventTime" (click)="onCreateEvent(eventCreatedModal);modal.close()">צור אירוע</button>
  </div>
</ng-template>

<ng-template #messageModalContent let-modal>
  <div class="row modal-header border-bottom-0 justify-content-center mt-4">
    <div class="col-12 font-size-14 font-weight-bold d-flex justify-content-space-between">
      <div>
        <span>הודעה ל</span>
        <span>{{initiative.firstName}} {{initiative.lastName}}</span>
      </div>
      <div>
        <fa-icon [icon]="faTimesCircle" (click)="modal.close()"></fa-icon>
      </div>
    </div>
  </div>
  <div class="separator"></div>
  <div class="modal-body">
    <div>
      <div>היי,</div>
      <div>
        <span>אשמח לארח את היוזמה שלך ב</span>
        <span class="font-weight-bold">{{selectedUserPlace.city}}</span>
        <span> ברחוב </span>
        <a href="https://www.google.com/maps?q={{selectedUserPlace.lat}},{{selectedUserPlace.lng}}" target="_blank">
          <span class="font-weight-bold">{{selectedUserPlace.street}} {{selectedUserPlace.houseNumber}}</span>
        </a>
      </div>
    </div>
    <div class="mt-2" *ngIf="userSelectedEventTime">
      <span>ביום </span>
      <span class="font-weight-bold">{{formatAsDay(userSelectedEventTime.date)}}</span>
      <span> בשעה</span>
    </div>
    <div class="mt-2 hours-box d-flex col-12" *ngIf="userSelectedEventTime">
      <div class="hour-box bg-primary col-3 p-2 text-center">{{userSelectedEventTime.hour}}</div>
    </div>
    <div class="mt-2">
      <span>תודה,</span>
      <br>
      <span class="font-weight-bold">{{user.name}}</span>
    </div>
  </div>
  <div class="modal-footer mt-4 mb-3 border-top-0 justify-content-center">
    <button class="col-11 btn btn-submit font-size-22 mb-3" type="button" (click)="sendMessage(messageSentModalContent);modal.close()">שליחת הודעה</button>
  </div>
</ng-template>

<ng-template #messageSentModalContent let-modal>
  <div class="modal-header flex-column border-bottom-0 row justify-content-center mt-4">
    <img class="success-icon" src="assets/Icons_Success.svg" alt="תמונה של קונפטי">
  </div>
  <div class="modal-header justify-content-center">
    <h4 class="modal-title">הודעתכם נשלחה!</h4>
  </div>
  <div class="modal-body">בקשתכם נשלחה לבעלי היוזמה כעת המתינו לאישור או המשיכו לחפש יוזמות נוספות</div>
  <div class="modal-body bg-text-light-blue font-weight-500 color-white">
    שימו לב, האירוע שלכם יווצר ויופיע בתוכנייה רק לאחר אישור בעלי היוזמה
  </div>
  <div class="modal-footer mt-4 mb-3 border-top-0 justify-content-center">
    <button class="col-11 btn btn-submit font-size-22 mb-3" type="submit" routerLink="/festival/{{festival.festivalEnglishName}}/initiatives-list" (click)="modal.close()">
      המשיכו לחפש יוזמה
    </button>
    <button class="col-11 btn btn-dismiss font-size-22" type="button" routerLink="/festival/{{festival.festivalEnglishName}}/my-area" (click)="modal.close()">
      לאזור האישי
    </button>
  </div>
</ng-template>

<ng-template #eventCreatedModal let-modal>
  <div class="modal-header row border-bottom-0 justify-content-center">
    <div class="col-12 d-flex justify-content-end">
      <fa-icon [icon]="faTimesCircle" (click)="modal.close()"></fa-icon>
    </div>
    <div class="col-12 d-flex justify-content-center">
      <img class="success-icon" src="assets/Icons_Success.svg" alt="תמונה של קונפטי">
    </div>
  </div>
  <div class="modal-header justify-content-center">
    <h4 class="modal-title">נוצר לכם אירוע!</h4>
  </div>
  <div class="modal-footer mt-4 mb-3 border-top-0 justify-content-center">
    <button class="col-11 btn btn-submit font-size-22 mb-3" type="submit" routerLink="/festival/{{festival.festivalEnglishName}}/my-events" (click)="modal.close()">לאירועים שלי</button>
  </div>
</ng-template>
