<div class="container-fluid mb-10 ps-0 pe-0" *ngIf="festival">
  <app-navbar-title-my-messages [festival]="festival" title="{{festival.name}}"></app-navbar-title-my-messages>
  <div class="desktop-content-box">
    <div class="d-flex festival-icon-box h-25 justify-content-center align-items-center mt-4 mb-4">
      <img [src]="'https://api.hanadiv.org/images/'+festival.logo" alt="לוגו של הפסטיבל" width="207" height="129">
    </div>
    <div class="d-flex festival-date font-size-31 text-center">
      <div class="col color-primary">{{formattedFromDate}}-{{formattedUntilDate}}</div>
    </div>
    <div class="d-flex time-until-festival-box justify-content-center mt-2" *ngIf="isTodayBeforeFestivalDates">
      <div class="d-flex time-until-festival align-items-center">
        <div class="col text-center seconds pe-5">
          <div class="time-number">{{timeUntilFestival.seconds}}</div>
          <div>שניות</div>
        </div>
        <div class="col text-center minutes">
          <div class="time-number">{{timeUntilFestival.minutes}}</div>
          <div>דקות</div>
        </div>
        <div class="col text-center hours">
          <div class="time-number">{{timeUntilFestival.hours}}</div>
          <div>שעות</div>
        </div>
        <div class="col text-center days ps-5">
          <div class="time-number">{{timeUntilFestival.days}}</div>
          <div>ימים</div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center mt-4 mb-5 text-center ps-3 pe-3">{{festival.about}}</div>
    <div class="d-flex justify-content-around">
      <button type="button" routerLink="/festival/{{festival.festivalEnglishName}}/options" class="btn btn-primary btn-lg col-10">יאללה מתחילים</button>
    </div>
  </div>
  <app-footer-navbar></app-footer-navbar>
</div>
