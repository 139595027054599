<div class="container-fluid p-0">
  <app-navbar-title-subtitle-step-back [festival]="festival" title="יצירת מקום" subTitle="הוספת תמונות" step="3" totalSteps="5"></app-navbar-title-subtitle-step-back>

  <!--mobile-->
  <div class="d-block d-sm-none container-fluid">
    <app-file-upload [title]="'הוסיפו תמונה של מקום האירוח'"></app-file-upload>
    <div class="col-10 m-auto" *ngIf="imageSource">
      <img [src]="imageSource" class="rounded img-thumbnail" alt="תמונת האירוע">
    </div>
    <div class="d-flex justify-content-center mt-4">
      <button class="btn btn-submit font-size-18 pe-3 ps-3" type="button" [disabled]="!imageSource" (click)="onSubmit()">לסימון חלונות זמינות</button>
    </div>
  </div>

  <!--desktop-->
  <div class="d-flex align-items-center desktop-content-box">
    <div class="d-none d-sm-block container-fluid">
      <app-file-upload [title]="'הוסיפו תמונה של מקום האירוח'"></app-file-upload>
      <div class="col-10 m-auto" *ngIf="imageSource">
        <img [src]="imageSource" class="rounded img-thumbnail" alt="תמונת האירוע">
      </div>
      <div class="d-flex justify-content-center mt-4">
        <button class="btn btn-submit font-size-18 pe-3 ps-3" type="button" [disabled]="!imageSource" (click)="onSubmit()">לסימון חלונות זמינות</button>
      </div>
    </div>
  </div>
</div>
