import {Component, OnDestroy, OnInit} from '@angular/core';
import {Festival} from '../shared/models/festival.model';
import {FestivalService} from '../shared/services/festival.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-festivals-list',
  templateUrl: './festivals-list.component.html',
  styleUrls: ['./festivals-list.component.css']
})
export class FestivalsListComponent implements OnInit, OnDestroy {

  festivals: Festival[];
  festivalsSubscription: Subscription;

  constructor(private festivalService: FestivalService) {
  }

  ngOnInit(): void {
    this.festivalsSubscription = this.festivalService.festivalsSubject.subscribe(festivals => {
      if (festivals) {
        this.festivals = festivals;
      }
    });
  }

  ngOnDestroy(): void {
    this.festivalsSubscription.unsubscribe();
  }

}
