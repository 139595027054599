<div class="container-fluid mb-10 p-0" *ngIf="festival">
  <app-my-area-navbar></app-my-area-navbar>
  <div class="container desktop-content-box">
    <div class="d-flex mt-3 my-area-link" routerLink="/festival/{{festival.festivalEnglishName}}/my-initiatives">
      <img src="assets/initiatives.svg" alt="אייקון לרשימת היוזמות שלי">
      <div>היוזמות שלי</div>
    </div>
    <div class="d-flex mt-3 my-area-link" routerLink="/festival/{{festival.festivalEnglishName}}/my-places">
      <img src="assets/places.svg" alt="אייקון לרשימת המקומות שלי">
      <div>המקומות שלי</div>
    </div>
    <div class="d-flex mt-3 my-area-link" routerLink="/festival/{{festival.festivalEnglishName}}/my-events">
      <img src="assets/events.svg" alt="אייקון לרשימת האירועים שלי">
      <div>הארועים שלי</div>
    </div>
  </div>
  <app-footer-navbar></app-footer-navbar>
</div>
