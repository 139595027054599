import {Component, OnDestroy, OnInit} from '@angular/core';
import {User} from '../shared/models/user.model';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../auth/auth.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: [
    '../shared/styles/forms-style.css',
    './reset-password.component.css'
  ]
})
export class ResetPasswordComponent implements OnInit, OnDestroy {

  user: User;
  restorePasswordForm: UntypedFormGroup;
  isLoading: boolean;
  errorMessage: string;
  message: string;
  restorePasswordSubscription: Subscription;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    private authService: AuthService
  ) {
  }

  ngOnInit(): void {
    const token = this.route.snapshot.queryParamMap.get('token');
    const email = this.route.snapshot.queryParamMap.get('email');

    this.restorePasswordForm = new UntypedFormGroup({
      email: new UntypedFormControl('', [Validators.required, Validators.email]),
      token: new UntypedFormControl('', [Validators.required]),
      password: new UntypedFormControl('', [Validators.required])
    });

    if (token && email) {
      this.restorePasswordForm.controls.email.setValue(email);
      this.restorePasswordForm.controls.token.setValue(token);
    } else {
      this.router.navigate(['/']);
    }
  }

  onSubmit(): void {
    if (this.restorePasswordForm.valid) {
      this.isLoading = true;
      this.errorMessage = null;
      this.restorePasswordSubscription = this.authService.onResetPassword(this.restorePasswordForm.value).subscribe(res => {
        this.isLoading = false;
        this.message = res.message;
        setTimeout(() => {
          this.router.navigate([this.authService.onSuccessRouterTarget]);
        }, 3000);
      }, error => {
        this.isLoading = false;
      });
    }
  }

  ngOnDestroy(): void {
    if (this.restorePasswordSubscription) {
      this.restorePasswordSubscription.unsubscribe();
    }
  }
}
