<div class="container-fluid mb-7 ps-0 pe-0" *ngIf="place">
  <app-navbar-title-back title="מקום"></app-navbar-title-back>
  <div class="d-flex flex-wrap">
    <div class="image-container text-center" [style.backgroundImage]="'url('+ place.path +')'"></div>
  </div>

  <div class="desktop-content-box">

    <div>
      <div class="icons-wrapper mt-4 mb-3 d-flex justify-content-space-evenly">
        <img [class.active]="place.isAccessible" src="assets/accessible.svg" class="place-icon" alt="תמונת נגישות">
        <img [class.active]="place.canHostNoisyEvents" src="assets/loud.svg" class="place-icon" alt="תמונת אפשר להרעיש">
        <img [class.active]="place.petFriendly" src="assets/pets.svg" class="place-icon" alt="תמונת ידידותי לבעלי חיים">
        <img [class.active]="place.hasParking" src="assets/parking.svg" class="place-icon" alt="תמונת חנייה">
        <img [class.active]="place.piano" src="assets/piano.svg" class="place-icon" alt="תמונת פסנתר">
      </div>

      <div class="after p-3 text-right">
        <div class="image-text-box">
          <div class="font-size-21 color-primary">
            <a href="https://www.google.com/maps?q={{place.lat}},{{place.lng}}" target="_blank">
              <span class="font-weight-bold" *ngIf="place.street">רחוב </span>
              <span class="font-weight-bold" *ngIf="place.street">{{place.street}}</span>
              <span class="font-weight-bold" *ngIf="place.street">, </span>
              <span class="font-weight-bold" *ngIf="place.street">{{place.houseNumber}}{{place.entrance}}</span>
              <span> {{place.neighborhood}}</span>
            </a>
          </div>
          <div class="font-size-16">
            <span>אירוח: </span>
            <span>{{place.host}}</span>
          </div>
        </div>
      </div>

      <!-- description -->
      <div class="mt-4 mb-3">
        <div class="col-12 title">תיאור המקום</div>
        <div class="col-12">{{place.description}}</div>
      </div>

      <!-- email -->
      <div class="mt-4 mb-3">
        <div class="col-12">
          <a class="textual-link" href="mailto:{{place.email}}" target="_blank">
            <fa-icon [icon]="faEnvelope"></fa-icon>
            {{place.email}}
          </a>
        </div>
      </div>

      <!-- phone -->
      <div class="mt-4 mb-3">
        <div class="col-12">
          <a class="textual-link" href="tel:{{place.phone}}" target="_blank">
            <fa-icon [icon]="faPhone"></fa-icon>
            {{place.phone}}
          </a>
        </div>
      </div>

      <!-- notes -->
      <div class="mb-3" *ngIf="place.notes">
        <div class="col-12 title">הערות מיוחדות</div>
        <div class="col-12">{{place.notes}}</div>
      </div>

      <!-- availability -->
      <div class="mb-3">
        <div class="col-12 title mb-3" *ngIf="place.numberOfEventsAtPlace < place.numberOfInitiativesWillingToHost">מתי פנוי?</div>
        <div *ngIf="place.numberOfEventsAtPlace < place.numberOfInitiativesWillingToHost">
          <div *ngFor="let item of place.availableDatetime" class="mb-2">
            <div class="col-12 font-size-17 mb-2">
              <span>ביום </span>
              <span class="font-weight-bold">{{formatAsDay(item.date)}}</span>
              <span> בשעות</span>
            </div>
            <div class="hours-box row col-12">
              <div class="col-3 m-1 p-2 text-center hour-box pointer"
                   [ngClass]="{'hour-selected':isHourSelected(item.date, hour), 'hour-unavailable':isHourUnavailable(item.date, hour)}" *ngFor="let hour of item.hours"
                   (click)="selectHour(item.date, hour)">
                {{hour}}
              </div>
            </div>
          </div>
        </div>
        <div class="text-center mb-5 bg-text-light-blue color-white p-2" *ngIf="place.numberOfEventsAtPlace >= place.numberOfInitiativesWillingToHost">
          המקום כבר הוזמן ע"י מקסימום היוזמות שהמקום יכול לארח
        </div>
      </div>

      <div class="alert alert-danger" *ngIf="errorMessage">{{errorMessage}}</div>

      <div class="text-center mb-5" *ngIf="place.numberOfEventsAtPlace < place.numberOfInitiativesWillingToHost">
        <!-- Error Messages -->
        <div *ngIf="userInitiatives.length===0" class="no-data-notice-text mb-3">על מנת להתארח במקום זה, יש להוסיף ראשית יוזמה תרבותית</div>
        <div *ngIf="userInitiatives.length>0 && userAvailableInitiatives.length===0" class="no-data-notice-text mb-3">כל היוזמות שלך כבר משודכות</div>
        <div *ngIf="userAvailableInitiatives.length>0">
          <div *ngIf="!place.isOwner" class="font-weight-bold font-size-17 mb-3" [class.color-red]="submitted && !eligible">נא סמן את השעות לפי הימים שבהם תרצה להתארח</div>
          <div *ngIf="place.isOwner" class="font-weight-bold font-size-17 mb-3" [class.color-red]="submitted && !eligible">נא סמן את השעות לפי הימים שבהם תרצה ליצור את האירוע</div>
        </div>
        <button *ngIf="!place.isOwner" class="btn btn-submit mb-5 font-size-19 font-weight-bold" type="button" [disabled]="userInitiatives.length===0" (click)="onSubmit(selectInitiativeModalContent, messageModalContent)">
          אני מעוניינ/ת להתארח
        </button>
        <button *ngIf="place.isOwner && userAvailableInitiatives.length>0" class="btn btn-submit mb-5 font-size-19 font-weight-bold" type="button" (click)="onSubmit(selectInitiativeModalContent, eventCreatedModal)">
          צור אירוע עם היוזמה שלך
        </button>
      </div>
    </div>
  </div>

  <app-footer-navbar></app-footer-navbar>
</div>

<ng-template #selectInitiativeModalContent let-modal>
  <div class="row modal-header border-bottom-0 justify-content-center mt-4">
    <div class="col-12 font-size-14 font-weight-bold d-flex justify-content-space-between">
      <div>
        <span>הודעה ל</span>
        <span>{{place.host}}</span>
      </div>
      <div>
        <fa-icon [icon]="faTimesCircle" (click)="modal.close()"></fa-icon>
      </div>
    </div>
    <a href="https://www.google.com/maps?q={{place.lat}},{{place.lng}}" target="_blank">
      <div class="col-12 font-size-12">{{place.street}} {{place.houseNumber}}{{place.entrance}}</div>
    </a>
  </div>
  <div class="separator"></div>
  <div class="modal-body">
    <div>אנא ביחרו את היוזמות אותן תירצו להציע למקום המארח</div>
    <div class="d-flex d-flex justify-content-center">
      <div class="user-initiative-item p-2 m-2"
           *ngFor="let initiative of userInitiatives"
           [ngClass]="{'selected-initiative':isUserInitiativeSelected(initiative)}"
           (click)="selectUserInitiative(initiative)">{{initiative.title}}</div>
    </div>
  </div>
  <!-- show this if place owner and initiative owner are not the same owner-->
  <div *ngIf="!place.isOwner" class="modal-footer mt-4 mb-3 border-top-0 justify-content-center">
    <button class="col-11 btn btn-submit font-size-22 mb-3" type="button" [disabled]="!selectedUserInitiative" (click)="proceedToSendMessage(messageModalContent);modal.close()">שליחת הודעה</button>
  </div>
  <div *ngIf="place.isOwner" class="modal-footer mt-4 mb-3 border-top-0 justify-content-center">
    <button class="col-11 btn btn-submit font-size-22 mb-3" type="button" [disabled]="!selectedUserInitiative" (click)="onCreateEvent(eventCreatedModal);modal.close()">צור אירוע</button>
  </div>
</ng-template>

<ng-template #messageModalContent let-modal>
  <div class="row modal-header border-bottom-0 justify-content-center mt-4">
    <div class="col-12 font-size-14 font-weight-bold d-flex justify-content-space-between">
      <div>
        <span>הודעה ל</span>
        <span>{{place.host}}</span>
      </div>
      <div>
        <fa-icon [icon]="faTimesCircle" (click)="modal.close()"></fa-icon>
      </div>
    </div>
    <a href="https://www.google.com/maps?q={{place.lat}},{{place.lng}}" target="_blank">
      <div class="col-12 font-size-12">{{place.street}} {{place.houseNumber}}{{place.entrance}}</div>
    </a>
  </div>
  <div class="separator"></div>
  <div class="modal-body">
    <div>
      <span>היי,</span>
      <span>אשמח להתארח במקום שלך עם היוזמה שלי:</span>
    </div>
    <div class="font-weight-bold">{{selectedUserInitiative.title}}</div>
    <div class="mt-2">
      <span>ביום </span>
      <span class="font-weight-bold">{{formatAsDay(userSelectedEventTime.date)}}</span>
      <span> בשעה</span>
    </div>
    <div class="mt-2 hours-box d-flex col-12">
      <div class="hour-box-before-send-message bg-primary col-3 p-2 text-center">{{userSelectedEventTime.hour}}</div>
    </div>
    <div class="mt-2">
      <span>למשך </span>
      <span>{{selectedUserInitiative.duration}}</span>
      <span> דקות</span>
    </div>
    <div class="mt-2">
      <span>תודה,</span>
      <br>
      <span class="font-weight-bold">{{user.name}}</span>
    </div>
  </div>
  <div class="modal-footer mt-4 mb-3 border-top-0 justify-content-center">
    <button class="col-11 btn btn-submit font-size-22 mb-3" type="button" (click)="sendMessage(messageSentModalContent);modal.close()">שליחת הודעה</button>
  </div>
</ng-template>

<ng-template #messageSentModalContent let-modal>
  <div class="modal-header flex-column border-bottom-0 row justify-content-center mt-4">
    <img class="success-icon" src="assets/Icons_Success.svg" alt="תמונה של קונפטי">
  </div>
  <div class="modal-header justify-content-center">
    <h4 class="modal-title">הודעתכם נשלחה!</h4>
  </div>
  <div class="modal-body">בקשתכם נשלחה למקום המארח כעת המתינו לאישור או המשיכו לחפש מקומות נוספים</div>
  <div class="modal-body bg-text-light-blue font-weight-500 color-white">
    שימו לב, האירוע שלכם יווצר ויופיע בתוכנייה רק לאחר אישור המקום המארח
  </div>
  <div class="modal-footer mt-4 mb-3 border-top-0 justify-content-center">
    <button class="col-11 btn btn-submit font-size-22 mb-3" type="submit" routerLink="/festival/{{festival.festivalEnglishName}}/places-list" (click)="modal.close()">
      המשיכו לחפש מקום
    </button>
    <button class="col-11 btn btn-dismiss font-size-22" type="button" routerLink="/festival/{{festival.festivalEnglishName}}/my-area" (click)="modal.close()">
      לאזור האישי
    </button>
  </div>
</ng-template>

<ng-template #eventCreatedModal let-modal>
  <div class="modal-header row border-bottom-0 justify-content-center">
    <div class="col-12 d-flex justify-content-end">
      <fa-icon [icon]="faTimesCircle" (click)="modal.close()"></fa-icon>
    </div>
    <div class="col-12 d-flex justify-content-center">
      <img class="success-icon" src="assets/Icons_Success.svg" alt="תמונה של קונפטי">
    </div>
  </div>
  <div class="modal-header justify-content-center">
    <h4 class="modal-title">נוצר לכם אירוע!</h4>
  </div>
  <div class="modal-footer mt-4 mb-3 border-top-0 justify-content-center">
    <button class="col-11 btn btn-submit font-size-22 mb-3" type="submit" routerLink="/festival/{{festival.festivalEnglishName}}/my-events" (click)="modal.close()">לאירועים שלי</button>
  </div>
</ng-template>
