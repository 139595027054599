import {Component, OnDestroy, OnInit} from '@angular/core';
import {Festival} from '../shared/models/festival.model';
import {HttpClient} from '@angular/common/http';
import {FestivalService} from '../shared/services/festival.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DataService} from '../shared/services/data.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-add-initiative2',
  templateUrl: './add-initiative2.component.html',
  styleUrls: [
    '../shared/styles/add-initiative-shared.css',
    '../shared/styles/forms-style.css',
    './add-initiative2.component.css'
  ]
})
export class AddInitiative2Component implements OnInit, OnDestroy {
  localStorageKey = 'addInitiativeFormData';
  currentStep = 2;
  festival: Festival;
  festivals: Festival[];
  festivalsSubscription: Subscription;
  genresSubscription: Subscription;
  genres: [{ genreID: string, title: string }];

  constructor(
    private http: HttpClient,
    private festivalService: FestivalService,
    private dataService: DataService,
    private route: ActivatedRoute,
    private router: Router) {
  }

  ngOnInit(): void {
    this.festivalsSubscription = this.festivalService.festivalsSubject.subscribe(festivals => {
      if (festivals) {
        this.festivals = festivals;
        const festivalEnglishName = this.route.snapshot.paramMap.get('festivalEnglishName');
        this.festival = this.festivalService.onGetCurrentFestival(festivalEnglishName);
        this.loadFormWithDataFromLocalStorage();
      }
    });
    this.genresSubscription = this.dataService.getGenres().subscribe(genres => {
      this.genres = genres;
    });
  }

  loadFormWithDataFromLocalStorage(): void {
    const localStorageData = this.dataService.getLocalStorageData(this.localStorageKey);
    if (!localStorageData) {
      this.router.navigate([`/festival/${this.festival.festivalEnglishName}/add-initiative/1`]);
    }
  }

  selectGenre(genre): void {
    this.dataService.addFormData(this.localStorageKey, this.currentStep, genre);
    this.router.navigate([`/festival/${this.festival.festivalEnglishName}/add-initiative/3`]);
  }

  ngOnDestroy(): void {
    this.festivalsSubscription.unsubscribe();
    this.genresSubscription.unsubscribe();
  }
}
